import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from 'App';
import { AuthProvider  } from 'context/AuthProvider';
import { BrowserRouter, Routes, Route, HashRouter } from 'react-router-dom';
import { store } from 'store/store';
import { Provider  } from 'react-redux';
import { ApiProvider } from '@reduxjs/toolkit/dist/query/react';
import { apiSlice } from 'features/api/apiSlice';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <HashRouter>
                <AuthProvider>
                    <ApiProvider api={apiSlice}>
                        <Routes>
                            <Route path="/*" element={<App/>}/>
                        </Routes>
                    </ApiProvider>
                </AuthProvider>
            </HashRouter>
        </Provider>
    </React.StrictMode>
);
