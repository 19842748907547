import { configureStore } from "@reduxjs/toolkit";
import usersReducer from '../features/users/usersSlice';
import languagesReducer from '../features/languages/languagesSlice';
import locationsReducer from '../features/locations/locationsSlice';
import questionnaireReducer from '../features/questionnaire/questionnaireSlice';

export const store = configureStore({
    reducer: {
        users: usersReducer,
        languages: languagesReducer,
        locations: locationsReducer,
        questionnaire: questionnaireReducer,
    }
});