import divCodes from "../../../constants/codes";
import { names } from "../../../constants/names";

import {
  getFormattedDateFromDateObject,
  getFormattedDateByFormat,
  getFormattedDate,
} from "../../../helpers/date_format";

const table11Parser = (responseDataFromApi) => {
  let table11Data = {
    regions: [],
    data: {},
  };

  let dataTyp = "monthOnMonth";

  let table11ChartData = {};

  let startDates = [];

  for (let qData of responseDataFromApi) {
    startDates.push(qData.startDate);
    for (let generalInflation of qData.generalInflations) {
      // list regions
      if (
        table11Data.regions.find(
          (region) => region.code === generalInflation.region.code
        ) === undefined
      ) {
        let newRegion = {
          name: generalInflation.region.translation[0].name,
          code: generalInflation.region.code,
        };
        table11Data.regions.push(newRegion);
      }
    }
  }

  let sortedDataByRegion = {};

  for (let region of table11Data.regions) {
    table11Data.firstRegionCode = region.code;
    if (sortedDataByRegion[region] === undefined) {
      sortedDataByRegion[region.code] = {};
      table11Data.data[region.code] = {
        // divisions: [],
        data: [],
      };
      table11ChartData[region.code] = [];
    }
  }

  for (let startDate of startDates) {
    for (let region of table11Data.regions) {
      sortedDataByRegion[region.code][startDate] = {
        divInflations: [],
      };
    }
  }

  for (let qData of responseDataFromApi) {
    for (let genInflation of qData.generalInflations) {
      sortedDataByRegion[genInflation.region.code][qData.startDate][
        "GeneralInflation"
      ] = genInflation[dataTyp];
    }
  }

  for (let qData of responseDataFromApi) {
    for (let genDivInflation of qData.divisionInflations) {
      sortedDataByRegion[genDivInflation.region.code][qData.startDate][
        "divInflations"
      ].push(genDivInflation);
    }
  }

  //! Table Data
  Object.keys(sortedDataByRegion).forEach((regionCode) => {
    Object.keys(sortedDataByRegion[regionCode]).forEach((startDate) => {
      let newRow = {
        generalInflation:
          sortedDataByRegion[regionCode][startDate]["GeneralInflation"].toFixed(
            2
          ),
        date: getFormattedDate(startDate),
        //food: ,
        //nonFood: ,
      };
      let nonFoodInflation = [];

      for (let divInflation of sortedDataByRegion[regionCode][startDate][
        "divInflations"
      ]) {
        //* Food inflation\
        let divCode = divInflation.division.code;
        if (divCode === divCodes.foodDivCode) {
          newRow.food = divInflation[dataTyp].toFixed(2);
        } else {
          // : 'Non-Food Inflation',
          nonFoodInflation.push(divInflation[dataTyp]);
        }
      }

      //* non-food inflation
      let nonFoodInfSum = 0;
      for (let nonFData of nonFoodInflation) {
        nonFoodInfSum = nonFoodInfSum + nonFData;
      }

      newRow.nonFood = Number(
        (nonFoodInfSum / nonFoodInflation.length).toFixed(2)
      );

      table11Data.data[regionCode].data.push(newRow);
    });
  });

  //! chart data
  Object.keys(table11Data.data).forEach((regionCode) => {
    for (let tableRow of table11Data.data[regionCode].data) {
      //generalInflation
      table11ChartData[regionCode].push({
        date: tableRow.date,
        name: "General Inflation",
        value: tableRow.generalInflation,
      });

      //food
      table11ChartData[regionCode].push({
        date: tableRow.date,
        name: names.foodCategoryName,
        value: tableRow.food,
      });

      //non food
      table11ChartData[regionCode].push({
        date: tableRow.date,
        name: "Non-Food Inflation",
        value: tableRow.nonFood,
      });
    }
  });

  // console.log({sortedDataByRegion});
  // console.log({ table11ChartData });

  return {
    table11ChartData,
    table11Data,
  };
};

export default table11Parser;
