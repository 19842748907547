import React, { useState, useEffect } from "react";
import {
  ItemIcon,
  QuestionnaireIcon,
  ArrowRightIcon,
  ArrowDownIcon,
  LangIcon,
  LocationIcon,
} from "components/custom_icons";
import { NavLink, useLocation } from "react-router-dom";
import {
  ArrowDownOutlined,
  DownOutlined,
  LeftOutlined,
  RightOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import useAuth from "hooks/useAuth";
import userRoles from "../user_roles";
import { Button, Tooltip } from "antd";
import { motion } from "framer-motion";
import "./sidebar_styles.css";

const SideBarBtn = ({ label, icon, pathTo, isChild = false }) => {
  const [active, setActive] = useState(false);
  const [isHover, setIsHover] = useState(false);

  let location = useLocation();

  useEffect(() => {
    setActive(location.pathname.includes(pathTo));
  }, [location, pathTo]);

  const colorShadow = active && {
    backgroundColor: "#fff",
    boxShadow: "1px 2px 9px #d8d8d8",
  };

  return (
    <NavLink
      to={pathTo}
      className=""
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div
        onMouseEnter={() => {
          if (!location.pathname.includes(pathTo)) {
            setIsHover(true);
          }
        }}
        onMouseLeave={() => setIsHover(false)}
        style={{
          display: "flex",
          cursor: "pointer",
          marginBottom: isChild ? "8px" : "10px",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px",
          width: isChild ? "220px" : "230px",
          height: isChild ? "30px" : "40px",
          ...colorShadow,
          borderRadius: isChild ? "5px" : "10px",
          backgroundColor: isHover ? "#F5F5F5" : "#fff",
        }}
      // className="sidebarclass"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {icon}
          <span style={{ marginLeft: "10px", color: "#000" }}>{label}</span>
        </div>
        <ArrowRightIcon />
      </div>
    </NavLink>
  );
};

const SideBarParentBtn = ({ label, icon, parentOf, children }) => {
  const [active, setActive] = useState(false);
  const [isHover, setIsHover] = useState(false);
  let location = useLocation();

  const colorShadow = active && {
    backgroundColor: "#fff",
    boxShadow: "1px 2px 3px #fafafa",
  };

  useEffect(() => {
    setActive(location.pathname.includes(parentOf) ? true : false);
  }, [location]);

  return (
    <>
      <div
        onClick={() => {
          if (location.pathname.includes(parentOf)) {
            return;
          }
          setActive(!active);
          // setActive(true);
        }}
        onMouseEnter={() => {
          if (!location.pathname.includes(parentOf)) {
            setIsHover(true);
          }
        }}
        onMouseLeave={() => setIsHover(false)}
        style={{
          // backgroundColor: active ? '#EEEEEE' : '#fff',
          backgroundColor: isHover ? "#F5F5F5" : "#fff",
          display: "flex",
          cursor: "pointer",
          // marginBottom: active ? '0px' : "10px",
          marginBottom: "10px",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px",
          width: "230px",
          height: "40px",
          ...colorShadow,
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {icon}
          <span style={{ marginLeft: "10px", color: "#000" }}>{label}</span>
        </div>

        {active && <ArrowDownIcon />}
        {!active && <ArrowRightIcon />}
      </div>

      {active && (
        <motion.div
          animate={{ opacity: 1, y: active ? 5 : -5 }}
          transition={{ type: "spring" }}
          style={{ backgroundColor: "#fff", marginBottom: "5px" }}
        >
          {children}
        </motion.div>
      )}
    </>
  );
};

const Sidebar = ({ isVisible, handleToggleChange }) => {
  const { auth } = useAuth();
  const [colBtnToolTipVisible, setColBtnToolTipVisible] = useState(false);

  return (
    <div>
      <div
        style={{
          width: isVisible ? "260px" : "50px",
          paddingTop: isVisible ? "40px" : "30px",
          position: "fixed",
          display: "flex",
          overflowY: "auto",
          flexDirection: "column",
          alignItems: "center",
          bottom: 0,
          left: 0,
          top: 60,
          backgroundColor: isVisible ? "#fff" : null,
          borderRight: isVisible ? "1px solid #f2f2f2" : null,
        }}
      >
        <Tooltip
          title={isVisible ? "Collapse" : "Expand"}
          // visible={colBtnToolTipVisible}
          mouseEnterDelay={0.5}
          mouseLeaveDelay={0}
        >
          <div
            style={{
              alignSelf: isVisible ? "flex-end" : "flex-start",
              marginBottom: isVisible ? "10px" : "0px",
              marginRight: isVisible ? "5px" : "0px",
              marginLeft: isVisible ? "0px" : "5px",
              zIndex: 10000,
            }}
          >
            <Button
              style={
                {
                  // position: 'absolute',
                }
              }
              onClick={() => {
                // setColBtnToolTipVisible(false);
                // setColBtnToolTipVisible(true);
                handleToggleChange();
              }}
              type="secondary"
              shape="circle"
              size="small"
              icon={isVisible ? <LeftOutlined /> : <RightOutlined />}
            />
          </div>
        </Tooltip>

        <div
          style={{
            visibility: isVisible ? "visible" : "hidden",
          }}
        >
          {auth.userRole === userRoles.STATISTICIAN && (
            <>
              <SideBarBtn
                pathTo={"/home/item"}
                label={"Items"}
                icon={<ItemIcon />}
              />
              <SideBarBtn
                pathTo={"/home/locations"}
                label={"Locations"}
                icon={<LocationIcon />}
                isParent={false}
                childrens={[
                  {
                    pathTo: "/home/locations/marketplace",
                    label: "Marketplace",
                  },
                ]}
              />
              <SideBarBtn
                pathTo={"/home/questionnaire"}
                label={"Questionnaire design"}
                icon={<QuestionnaireIcon />}
              />
            </>
          )}

          {([userRoles.DIRECTORATE_DIRECTOR, userRoles.DEPUTY_DIRECTOR_GENERAL, userRoles.DIRECTOR_GENERAL].includes(auth.userRole)) && (
            <>
              <SideBarBtn
                pathTo={"/home/questionnaire"}
                label={"Questionnaire design"}
                icon={<QuestionnaireIcon />}
              />
            </>
          )}

          {(auth.userRole === userRoles.DIRECTORATE_DIRECTOR ||
            auth.userRole === userRoles.STATISTICIAN) && (
              <>
                {/* import  */}

                <SideBarParentBtn
                  label={"Import"}
                  icon={<QuestionnaireIcon />}
                  parentOf={"import"}
                >
                  {/* director cpibasket and cpi */}

                  <SideBarBtn
                    pathTo={"/home/import/quotation"}
                    label={"Import Quotations"}
                    icon={<QuestionnaireIcon />}
                    isChild={true}
                  />
                  <SideBarBtn
                    pathTo={"/home/import/categories"}
                    label={"Import Categories"}
                    icon={<QuestionnaireIcon />}
                    isChild={true}
                  />
                  <SideBarBtn
                    pathTo={"/home/import/addresses"}
                    label={"Import Addresses"}
                    icon={<QuestionnaireIcon />}
                    isChild={true}
                  />

                  {auth.userRole === userRoles.DIRECTORATE_DIRECTOR && (
                    <>
                      <SideBarBtn
                        pathTo={"/home/import/basket"}
                        label={"Import CPI Basket"}
                        icon={<QuestionnaireIcon />}
                        isChild={true}
                      />
                      <SideBarBtn
                        pathTo={"/home/import/cpi"}
                        label={"Import CPI"}
                        icon={<QuestionnaireIcon />}
                        isChild={true}
                      />
                    </>
                  )}

                  <SideBarBtn
                    pathTo={"/home/import/questionnaire"}
                    label={"Import Questionnaire"}
                    icon={<QuestionnaireIcon />}
                    isChild={true}
                  />
                </SideBarParentBtn>

                {/* import  */}
              </>
            )}

          {(auth.userRole === userRoles.DIRECTORATE_DIRECTOR ||
            auth.userRole === userRoles.STATISTICIAN) && (
              <>
                <SideBarBtn
                  pathTo={"/home/cpi"}
                  label={"CPI Basket"}
                  icon={<QuestionnaireIcon />}
                />
              </>
            )}

          {([userRoles.DATA_CLEANER,
          userRoles.STATISTICIAN,
          userRoles.DIRECTORATE_DIRECTOR,
          userRoles.DEPUTY_DIRECTOR_GENERAL,
          userRoles.DIRECTOR_GENERAL].includes(auth.userRole)) && (
              <>
                <SideBarBtn
                  pathTo={"/home/display"}
                  label={"Cleaned Data"}
                  icon={<QuestionnaireIcon />}
                />
              </>
            )}

          {auth.userRole === userRoles.ADMIN && (
            <>
              <SideBarBtn
                pathTo={"/home/users"}
                label={"Users"}
                icon={
                  <UserOutlined
                    height={12}
                    width={12}
                    style={{ color: "#000" }}
                  />
                }
              />
              <SideBarBtn
                pathTo={"/home/languages"}
                label={"Languages"}
                icon={<LangIcon />}
              />
              <SideBarBtn
                pathTo={"/home/locations"}
                label={"Locations"}
                icon={<LocationIcon />}
                isParent={false}
                childrens={[
                  {
                    pathTo: "/home/locations/marketplace",
                    label: "Marketplace",
                  },
                ]}
              />
            </>
          )}

          {/* <SideBarBtn pathTo={'/home/setting'} label={'Setting'} icon={<SettingIcon/>}/> */}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
