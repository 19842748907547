import React, { useEffect, useState, useRef } from "react";
import "./login_page.css";
import LangBtn from "components/lang_btn";
import EmisLogo from "components/emis_logo";
import essLogo from "../../assets/ess-logo-white.png";
import { Input, Checkbox, Button, Dropdown, Menu, Form } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import bgLogin from "../../assets/bg-login.svg";
import { getWindowSize } from "helpers/window-size";
import axios from "api/axios";
import useAuth from "hooks/useAuth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import userRoles from "components/user_roles";

const USER_REGEX = /^[A-z][A-z0-9-_]{5,50}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{7,40}$/;
const LOGIN_URL = "/user/login";

const menu = (
  <Menu
    items={[
      {
        key: "1",
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.antgroup.com"
          >
            En
          </a>
        ),
      },
      {
        key: "2",
        label: (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.antgroup.com"
          >
            Am
          </a>
        ),
      },
    ]}
  />
);

function Login() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location?.state?.from?.pathname || "/";

  //
  const usernameRef = useRef();
  const errRef = useRef();

  const [username, setUsername] = useState("");
  const [validUsername, setValidUsername] = useState(false);
  const [usernameFocus, setUsernameFocus] = useState(false);

  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");

  const [isUserRemembered, setIsUserRemembered] = useState(true);

  const [loading, setLoading] = useState(false);

  //sets focus on the user name input when the page loads
  useEffect(() => {
    usernameRef.current.focus();
  }, []);

  //checks username validity using the regex defined when ever the username input value changes
  useEffect(() => {
    setValidUsername(USER_REGEX.test(username));
  }, [username]);

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
  }, [password]);

  useEffect(() => {
    setErrMsg("");
  }, [username, password]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  //remember me checkbox on change function
  const onChange = (e) => {
    setIsUserRemembered(e.target.checked);
  };

  //* sign in function
  const onSignIn = async () => {
    //*If for some reason the button was enabled by JS hack
    const v1 = USER_REGEX.test(username);
    const v2 = PWD_REGEX.test(password);
    // if(!v1 || !v2){
    //   setErrMsg('Invalid Entry');
    //   return;
    // }

    //*-----------------------------------------
    try {
      setLoading(true);
      //*send login request to server using username and password
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ userName: username, password }),
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          withCredentials: true,
        }
      );

      //*Grab access token from the response
      const accessToken = response?.data?.data?.token;
      const refreshToken = response?.data?.data?.refreshToken;
      const userRole = response?.data?.data?.role;
      const userId = Number(response?.data?.data.id);

      //*Store username pass userRole and accessToken to auth global state using use context api
      const authData = {
        username,
        userRole,
        accessToken,
        refreshToken,
        userId,
      };
      localStorage.setItem("user", JSON.stringify(authData));
      localStorage.setItem("isLoggedIn", true);
      setAuth(authData);

      //*clear input fields here
      setUsername("");
      setPassword("");
      navigate("/home/*", { replace: true });
    } catch (error) {
      if (!error?.response) {
        setErrMsg("No Server Response");
      } else if (error.response?.status === 409) {
        setErrMsg("some error message based on the servers feedback");
      } else {
        setErrMsg("Sign in Failed");
      }
      errRef.current.focus();
    }
    setLoading(false);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100vh",
          backgroundImage: `url(${bgLogin})`,
          display: "flex",
          padding: "10px 50px",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <div style={{ alignSelf: "end" }}>
          <Dropdown overlay={menu} placement="bottom" arrow>
            <Button type="link">
              <LangBtn />
            </Button>
          </Dropdown>
        </div> */}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            height:
              windowSize.innerHeight < 750 ? null : windowSize.innerHeight,
            width: "500px",
            backgroundColor: "#fff",
            boxShadow: "2px 2px 10px #e2e2e277",
            borderRadius: "5px",
            padding: "0px 30px",
          }}
        >
          <div style={{ alignSelf: "center" }}>
            <img
              style={{ marginBottom: "1em" }}
              src={require("../../assets/logo-white.png")}
              alt="Emis Logo"
              height={280}
              width={380}
            />
            {/* <EmisLogo /> */}
          </div>

          <div>
            <div
              style={{
                borderBottom: "2px solid #0a7acc",
                width: "40px",
                color: "#0a7acc",
                fontSize: "1rem",
                marginBottom: "2em",
              }}
            >
              Login
            </div>

            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"}>
              {errMsg}
            </p>

            <div style={{ width: "450px" }}>
              <Form
                initialValues={{
                  remember: true,
                }}
                autoComplete="off"
              >
                <Form.Item
                  hasFeedback
                  validateStatus={
                    !validUsername && username
                      ? "error"
                      : username !== ""
                        ? "success"
                        : null
                  }
                >
                  {/* <Form.Item hasFeedback validateStatus={(!validUsername && username) ? "error" : username !== "" ? "success" : null} help={!validUsername && username ? "Username must be greater than 5 characters" : null}> */}

                  <Input
                    ref={usernameRef}
                    status={!validUsername && username ? "error" : null}
                    onFocus={() => setUsernameFocus(true)}
                    onBlur={() => setUsernameFocus(false)}
                    onChange={(e) => setUsername(e.target.value)}
                    block="true"
                    size="large"
                    placeholder="Username"
                    prefix={<UserOutlined style={{ color: "#0a7acc" }} />}
                  />
                </Form.Item>

                <Form.Item
                  hasFeedback
                  validateStatus={
                    !validPassword && password
                      ? "error"
                      : password !== ""
                        ? "success"
                        : null
                  }
                >
                  <Input.Password
                    status={!validPassword && password ? "error" : null}
                    onFocus={() => setPasswordFocus(true)}
                    onBlur={() => setPasswordFocus(false)}
                    onChange={(e) => setPassword(e.target.value)}
                    block="true"
                    size="large"
                    placeholder="Password"
                    prefix={<LockOutlined style={{ color: "#0a7acc" }} />}
                  />
                </Form.Item>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "20px",
                    marginTop: "20px",
                  }}
                >
                  <Checkbox checked={isUserRemembered} onChange={onChange}>
                    Remember me
                  </Checkbox>
                  <Button
                    onClick={() => {
                      navigate("/forgot");
                    }}
                    type="link"
                  >
                    Forgot your password?
                  </Button>
                </div>

                <Button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  // disabled={!validUsername ? true : false}
                  disabled={!validUsername || !validPassword ? true : false}
                  onClick={onSignIn}
                  type="primary"
                  block="true"
                  loading={loading}
                >
                  Sign In
                </Button>
              </Form>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "400px",
            }}
          >
            <img
              style={{ marginBottom: "10px", marginTop: "10px" }}
              src={require("../../assets/logo.png")}
              // src={require("../../assets/ess-logo.png")}
              height="65px"
              width="101.1px"
              // height="45.66px"
              // width="101.1px"
              alt="ess logo"
            />
            <span style={{ marginBottom: "10px", color: "#a5a0a0" }}>
              Copyright ©2023 Ethiopian Statistical Service
            </span>
            <span style={{ marginBottom: "20px", color: "#a5a0a0" }}>
              Powered by: 360Ground
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
