import { createSlice } from "@reduxjs/toolkit";
import { getUserType } from "helpers/get_user_type";

const initialState = {
    users: []
}


export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUsers: (state, action) => {
            const usersPayload = action.payload;

            for(let i = 0; i < usersPayload.length; i++){
                usersPayload[i].key = usersPayload[i].id;
                usersPayload[i].status = usersPayload[i].disabled ? "Inactive" : "Active";
                usersPayload[i].userType = getUserType(usersPayload[i].role);
                state.users.push(usersPayload[i]);
            }

        },
        clearUsers: (state, action) => {
            state.users = [];
        }
    }
});

export const { setUsers, clearUsers } = usersSlice.actions;
export default usersSlice.reducer;