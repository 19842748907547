import { getConvertedCode } from "../code_convert";
import { v4 as uuid } from "uuid";
import { addMonths, subYears } from "date-fns";
import {
  getFormattedDateFromDateObject,
  getFormattedDateByFormat,
  getFormattedDate,
} from "../date_format";
import { dateFormats } from "../../constants/date_formats";
import table1Parser from "../../features/api/chart_&_table_parsers/table_&_chart_1_parser";
import table2Parser from "../../features/api/chart_&_table_parsers/table_&_chart_2_parser";
import table3Parser from "../../features/api/chart_&_table_parsers/table_&_chart_3_parser";
import table4Parser from "../../features/api/chart_&_table_parsers/table_&_chart_4_parser";
import table5Parser from "../../features/api/chart_&_table_parsers/table_&_chart_5_parser";
import table6Parser from "../../features/api/chart_&_table_parsers/table_&_chart_6_parser";
import table7Parser from "../../features/api/chart_&_table_parsers/table_&_chart_7_parser";
import table8Parser from "../../features/api/chart_&_table_parsers/table_&_chart_8_parser";
import divCodes from "../../constants/codes";
import { names } from "../../constants/names";
import table9Parser from "../../features/api/chart_&_table_parsers/table_&_chart_9_parser";
import table10Parser from "../../features/api/chart_&_table_parsers/table_&_chart_10_parser";
import table11Parser from "../../features/api/chart_&_table_parsers/table_&_chart_11_parser";

const questPrevParser = (temp) => {
  //! start

  // console.log('temp: ', temp);

  //* ---------------------------------        national general inflation related      ---------------------------------

  for (let qData of temp.data) {
    console.log("qData: ", qData);

    let startDateObject = Date.parse(qData.startDate);

    // let theMonthBeforeDateObject = subMonths(startDateObject, 1);
    let theYearbeforeDateObject = subYears(startDateObject, 1);
    let theNextPublishUpdateMonthDateObject = addMonths(startDateObject, 1);

    let fromDateData =
      getFormattedDateFromDateObject(theYearbeforeDateObject) +
      " to " +
      getFormattedDateFromDateObject(startDateObject);

    // console.log(`startDateObject: ${startDateObject} || \ntheYearbeforeDateObject: ${theYearbeforeDateObject} || \ntheNextPublishUpdateMonthDateObject: ${theNextPublishUpdateMonthDateObject} || \nfromDateData: ${fromDateData}`)

    qData["startDateFormatted"] = getFormattedDateByFormat(
      qData.startDate,
      dateFormats.monthAndYear
    );
    qData["fromDateData"] = fromDateData;
    qData["isMovingAvgUp"] =
      qData.nationalGeneralInflation.movingAverage > 0 ? true : false;
    qData["nextUpdateMonth"] = getFormattedDateByFormat(
      theNextPublishUpdateMonthDateObject,
      dateFormats.monthAndYear
    );
  }

  console.log("loop done !!!");

  //! ---------------------------------      END  national general inflation related      ---------------------------------

  const { table1ChartData, table1Data } = table1Parser(temp.data);
  console.log("table1Data done !!!");

  const { table2ChartData, table2Data } = table2Parser(temp.data);
  console.log("table2Data done !!!");

  const { table3ChartData, table3Data } = table3Parser(temp.data);
  console.log("table3Data done !!!");

  const { table4ChartData, table4Data } = table4Parser(temp.data);
  console.log("table4Data done !!!");

  const { table5ChartData, table5Data } = table5Parser(temp.data);
  console.log("table5Data done !!!");

  const { table6ChartData, table6Data } = table6Parser(temp.data);
  console.log("table6Data done !!!");

  const { table7ChartData, table7Data } = table7Parser(temp.data);
  console.log("table7Data done !!!");

  const { table8ChartData, table8Data } = table8Parser(temp.data);
  console.log("table8Data done !!!");

  const { table9ChartData, table9Data } = table9Parser(temp.data);
  console.log("table9Data done !!!");

  const { table10ChartData, table10Data } = table10Parser(temp.data);
  console.log("table10Data done !!!");

  const { table11ChartData, table11Data } = table11Parser(temp.data);
  console.log("table11Data done !!!");

  //* ---------------------------------        Table 9 related      ---------------------------------

  //! ---------------------------------        End Table 9 related      ---------------------------------

  let finalData = {
    ...temp.data,

    //table 1
    table1ChartData: table1ChartData.map((chartData) => ({
      ...chartData,
      date: getFormattedDate(chartData.date),
    })),
    table1Data: table1Data.map((tableData) => ({
      ...tableData,
      date: getFormattedDate(tableData.date),
    })),

    //table 2
    table2ChartData: table2ChartData.map((chartData) => ({
      ...chartData,
      date: getFormattedDate(chartData.date),
    })),
    table2Data: table2Data.map((tableData) => ({
      ...tableData,
      date: getFormattedDate(tableData.date),
    })),

    //table 3
    table3ChartData: table3ChartData.map((chartData) => ({
      ...chartData,
      date: getFormattedDate(chartData.date),
    })),
    table3Data: table3Data.map((tableData) => ({
      ...tableData,
      date: getFormattedDate(tableData.date),
    })),

    //table 4
    table4ChartData: {
      ...table4ChartData,
    },
    table4Data: {
      ...table4Data,
    },

    //table 5
    table5ChartData: table5ChartData.map((chartData) => ({
      ...chartData,
      date: getFormattedDate(chartData.date),
    })),
    table5Data: {
      ...table5Data,
      data: table5Data.data.map((tableData) => ({
        ...tableData,
        date: getFormattedDate(tableData.date),
      })),
    },

    //table 6
    table6ChartData: {
      ...table6ChartData,
    },
    table6Data: {
      ...table6Data,
    },

    //table 7
    table7ChartData: table7ChartData.map((chartData) => ({
      ...chartData,
      date: getFormattedDate(chartData.date),
    })),
    table7Data: {
      ...table7Data,
      data: table7Data.data.map((tableData) => ({
        ...tableData,
        date: getFormattedDate(tableData.date),
      })),
    },

    //table 8
    table8ChartData: {
      ...table8ChartData,
    },
    table8Data: {
      ...table8Data,
    },

    //table 9
    table9ChartData: {
      ...table9ChartData,
      data: table7Data.data.map((tableData) => ({
        ...tableData,
        date: getFormattedDate(tableData.date),
      })),
    },
    table9Data: {
      ...table9Data,
    },

    //table 10
    table10ChartData: {
      ...table10ChartData,
    },
    table10Data: {
      ...table10Data,
    },

    //table 11
    table11ChartData: {
      ...table11ChartData,
    },
    table11Data: {
      ...table11Data,
    },
  };

  // console.log('final data: ', finalData);

  return finalData;
};

export default questPrevParser;
