 import { useLocation, Navigate, Outlet } from "react-router-dom";
 import useAuth from "hooks/useAuth";
  
 export const RequireAuthWithRole = ({allowedRole}) =>{
    const { auth } = useAuth();
    const location = useLocation();

    return (
        allowedRole.includes(auth?.userRole)
            ? <Outlet/> 
            : auth?.username 
            ? <Navigate to='/unauthorized' state={{ from: location }} replace />
            : <Navigate to='/login' state={{ from: location }} replace />
    );
 }

 export const RequireAuth = () => {
    const {auth} = useAuth();
    const location = useLocation();

    return (
        auth?.username 
            ? <Outlet/>
            : <Navigate to='/login' state={{ from: location }} replace/>
    );
 }




 