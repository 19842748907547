import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  locationTypes,
  questionnaireStatuses,
  categoryTypes,
} from "components/user_roles";
import { getFormattedDate } from "helpers/date_format";
// import { format } from 'date-fns';
import { getConvertedCode } from "../../helpers/code_convert";
import { v4 as uuid } from "uuid";
import questPrevParser from "../../helpers/parsers/questionnaire_preview_parser";
import { urls } from "constants/strings";

const replaceItemsKey = (response) => {
  let temp = { ...response };
  //* change any key that is group || class || subclass || item to children
  for (const division of temp.data) {
    division["children"] = division["group"];
    delete division.group;
    division["type"] = "Division";
    division["oldCode"] = division.code;
    // division['cpiCode'] = division.code;

    //* replace group with children
    for (const dGroup of division["children"]) {
      dGroup["children"] = dGroup["class"];
      delete dGroup.class;
      dGroup["oldCode"] = dGroup.code;
      //!
      // dGroup.code = getConvertedCode(division.code) + getConvertedCode(dGroup.code);
      dGroup["type"] = "Group";

      //* replace class with children
      for (const gClass of dGroup["children"]) {
        gClass["children"] = gClass["subclass"];
        delete gClass.subclass;
        gClass["oldCode"] = gClass.code;
        gClass["type"] = "Class";
        //!
        // gClass.code = getConvertedCode(dGroup.code) + getConvertedCode(gClass.code);

        //* replace subclass with children
        for (const subclass of gClass["children"]) {
          subclass["children"] = subclass["item"];
          delete subclass.item;
          subclass["oldCode"] = subclass.code;
          subclass["type"] = "Subclass";
          //!
          // subclass.code = getConvertedCode(gClass.code) + getConvertedCode(subclass.code);

          //* replace item with children
          for (const item of subclass["children"]) {
            item["oldCode"] = item.code;
            item["type"] = "Item";
            //!
            // item.code = getConvertedCode(subclass.code) + getConvertedCode(item.code);
          }

          //* sort item by its code
          subclass.children.sort(
            (objA, objB) => Number(objA.code) - Number(objB.code)
          );
        }

        //* sort subclass by its code
        gClass.children.sort(
          (objA, objB) => Number(objA.code) - Number(objB.code)
        );
      }

      //* sort class by its code
      dGroup.children.sort(
        (objA, objB) => Number(objA.code) - Number(objB.code)
      );
    }

    //* sort group by its code
    division.children.sort(
      (objA, objB) => Number(objA.code) - Number(objB.code)
    );
  }

  //* sort division by its code
  temp.data.sort((objA, objB) => Number(objA.code) - Number(objB.code));

  return temp;
};

//* regions

const replaceRegionKeys = (response) => {
  let temp = { ...response };
  for (const region of temp.data) {
    region["type"] = locationTypes.Region;
    region["unmodifiedCode"] = region.code;
  }

  return temp;
};

//* locations

const replaceLocationsKey = (response) => {
  let temp = { ...response };
  //* change any key that is region || zone || woreda || branch || city || marketplace to children
  for (const region of temp.data) {
    region["unmodifiedCode"] = region.code;
    region["children"] = region["zone"];
    region["items"] = region["zone"];
    region["marketplaceAddContent"] = region["zone"];
    delete region.Zone;
    region["type"] = locationTypes.Region;
    region["value"] = region.code;
    region["label"] = region.translation[0].name;

    //* replace zone with children
    for (const zone of region["children"]) {
      zone["unmodifiedCode"] = zone.code;
      zone["children"] = zone["woreda"];
      zone["items"] = zone["woreda"];
      zone["marketplaceAddContent"] = zone["woreda"];
      delete zone.Woreda;
      zone["value"] = String(zone.code) + "|" + String(region.code);
      zone.code = Number(String(region.code) + String(zone.code));
      zone["type"] = locationTypes.Zone;
      zone["label"] = zone.translation[0].name;

      //* replace Woreda with children
      for (const woreda of zone["children"]) {
        woreda["unmodifiedCode"] = woreda.code;
        woreda["children"] = woreda["marketplace"];
        woreda["items"] = woreda["marketplace"];
        woreda["marketplaceAddContent"] = [];
        delete woreda.marketplace;
        woreda["value"] = String(woreda.code) + "|" + String(zone.code);
        woreda.code = Number(String(zone.code) + String(woreda.code));
        woreda["type"] = locationTypes.Woreda;
        woreda["label"] = woreda.translation[0].name;

        for (const marketplace of woreda["children"]) {
          marketplace["unmodifiedCode"] = marketplace.code;
          marketplace["value"] =
            String(marketplace.code) + "|" + String(woreda.code);
          marketplace["type"] = locationTypes.Marketplace;
          marketplace["label"] = marketplace.translation[0].name;
        }

        //* replace Branch with children
        // for (const branch of woreda["children"]) {
        //   branch["unmodifiedCode"] = String(Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))) + '|' + String(branch.code);
        //   // branch["unmodifiedCode"] = String(branch.code);
        //   branch["children"] = branch["city"];
        //   branch["items"] = branch["city"];
        //   delete branch.City;
        //   branch["value"] = String(branch.code) + "|" + String(woreda.code);
        //   branch.code = Number(String(woreda.code) + String(branch.code));
        //   branch["type"] = locationTypes.Branch;
        //   branch["label"] = branch.translation[0].name;

        //* replace City with children
        // for (const city of branch["children"]) {
        //   city["unmodifiedCode"] = String(Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))) + '|' + String(city.code);
        //   // city["unmodifiedCode"] =  String(city.code);
        //   city["children"] = city["marketplace"];
        //   delete city.marketplace;
        //   city["value"] = String(city.code) + "|" + String(branch.code);
        //   city.code = Number(String(branch.code) + String(city.code));
        //   city["type"] = locationTypes.City;
        //   city["label"] = city.translation[0].name;

        // for (const marketplace of city["children"]) {
        //   // console.log('marketplace code from api: ', marketplace.code);
        //   marketplace["unmodifiedCode"] = marketplace.code;
        //   marketplace["value"] =
        //     String(marketplace.code) + "|" + String(city.code);
        //   // marketplace.code = Number(
        //   //   String(city.code) + String(marketplace.code)
        //   // );
        //   marketplace["type"] = locationTypes.Marketplace;
        //   marketplace["label"] = marketplace.translation[0].name;
        // }

        // city.children.sort(
        //   (objA, objB) => Number(objA.code) - Number(objB.code)
        // );
        // }

        //   branch.children.sort(
        //     (objA, objB) => Number(objA.code) - Number(objB.code)
        //   );
        // }

        woreda.children.sort(
          (objA, objB) => Number(objA.code) - Number(objB.code)
        );
      }

      zone.children.sort((objA, objB) => Number(objA.code) - Number(objB.code));
    }

    region.children.sort((objA, objB) => Number(objA.code) - Number(objB.code));
  }

  //* sort the data based on its created at date
  // temp.data.sort(
  //     (objA, objB) => Date.parse(objB.createdAt) - Date.parse(objA.createdAt),
  // );
  temp.data.sort((objA, objB) => Number(objA.code) - Number(objB.code));

  // console.log('temp: ', temp);
  return temp;
};

// const questionnaireStatuses = {
//     "PENDING_APPROVAL": "PENDING_APPROVAL",

// }

const replaceQuestionnaireKeys = (response) => {
  let temp = { ...response };

  for (const questionnaire of temp.data) {
    if (questionnaire.approved) {
      questionnaire.status = "Approved";
    } else if (questionnaire.disabled) {
      questionnaire.status = "Disabled";
    } else if (questionnaire.isDraft) {
      questionnaire.status = "Draft";
    } else {
      if (questionnaire.status === questionnaireStatuses.PENDING_APPROVAL) {
        questionnaire.status = "PENDING";
      } else {
        questionnaire.status = questionnaire.quotationsStatus;
      }
    }

    questionnaire.collection = "0%";
  }

  //* sort the data based on its created at date
  temp.data.sort(
    (objA, objB) => Date.parse(objB.createdAt) - Date.parse(objA.createdAt)
  );

  return temp;
};

const replaceCPIBasketKeys = (response) => {
  let temp = { ...response };

  for (const CPIBasket of temp.data) {
    // CPIBasket.createdAt = format(Date.parse(CPIBasket.createdAt), 'dd-MM-yyyy');
    CPIBasket.itemsCount = CPIBasket._count.items;
  }

  //* sort the data based on its created at date
  temp.data.sort(
    (objA, objB) => Date.parse(objB.createdAt) - Date.parse(objA.createdAt)
  );

  return temp;
};

const replaceuserskeys = (response) => {
  let temp = { ...response };

  temp.data.sort(
    (objA, objB) => Date.parse(objB.createdAt) - Date.parse(objA.createdAt)
  );

  return temp;
};

const transformApprovedQuotations = (response) => {
  let temp = { ...response };
  // let result = [];

  // for(let questionnaireItem of response.data.questionnaireItems){

  // }

  // result.name = response.data.questionnaireItems[0].item.translation[0].name;

  /* 
  Item:
    name,
    code,
    unit,
    variation,
    deviation,
    geomean,
    minQ,
    maxQ,
    ratio
  */

  // for (const quotationData of temp.data) {
  //   console.log(quotationData);
  // }

  // console.log(result);

  return temp;
};

export const apiSlice = createApi({
  reducerPath: "api",

  baseQuery: fetchBaseQuery({
    baseUrl: urls.baseEssUrl,
    prepareHeaders: (headers, { getState }) => {
      headers.set(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("user")).accessToken}`
      );
      return headers;
    },
  }),

  tagTypes: [
    "users",
    "langs",
    "branches",
    "marketplaces",
    "division",
    "group",
    "class",
    "subclass",
    "items",
    "allItemsData",
    "allLocationsData",
    "measurement",
    "regions",
    "zones",
    "woredas",
    "cities",
    "questionnaire",
    "questionnaireById",
    "CPIBasket",
    "CPIBasketByRegion",
    "approvedQuotations",
    "questionnaireStatus",
    "userStatus",
    "questionnairePreview",
  ],

  endpoints: (builder) => ({
    //* user related
    getUsers: builder.query({
      query: () => "/admin/getUsers",
      providesTags: ["users"],
      transformResponse: (response) => {
        return replaceuserskeys(response);
      },
    }),
    getUserById: builder.query({
      query: (arg) => {
        const { userId } = arg;
        return {
          url: "/admin/getuser/",
          params: { userId },
        };
      },
    }),
    addUser: builder.mutation({
      query: (user) => ({
        url: "/admin/createUser",
        method: "POST",
        body: user,
      }),
      invalidatesTags: ["users"],
    }),
    updateUser: builder.mutation({
      query: (user) => ({
        url: "/admin/updateUser",
        method: "PUT",
        body: user,
      }),
      invalidatesTags: ["users"],
    }),
    disableUser: builder.mutation({
      query: (user) => ({
        url: "/admin/disableUser",
        method: "PUT",
        body: user,
      }),
      invalidatesTags: ["users"],
    }),
    changeLanguage: builder.mutation({
      query: (data) => ({
        url: "/user/account/changeLanguage",
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [
        "users",
        "langs",
        "branches",
        "marketplaces",
        "division",
        "group",
        "class",
        "subclass",
        "items",
        "allItemsData",
        "allLocationsData",
        "measurement",
        "regions",
        "zones",
        "woredas",
        "cities",
        "questionnaire",
        "questionnaireById",
        "CPIBasket",
        "CPIBasketByRegion",
        "approvedQuotations",
        "questionnaireStatus",
        "userStatus",
        "questionnairePreview",
      ],
    }),
    //* language related
    getLanguages: builder.query({
      query: () => "/common/language",
      providesTags: ["langs"],
    }),
    addLanguage: builder.mutation({
      query: (language) => ({
        url: "/admin/createLanguage",
        method: "POST",
        body: language,
      }),
      invalidatesTags: ["langs"],
    }),

    //* division related
    getDivisions: builder.query({
      query: () => "/common/category/division",
      providesTags: ["division"],
    }),

    addDivision: builder.mutation({
      query: (division) => ({
        url: "/statistician/createDivision",
        method: "POST",
        body: division,
      }),
      invalidatesTags: ["division", "allItemsData"],
    }),

    //* groups related
    getGroups: builder.query({
      query: () => "/common/category/group",
      providesTags: ["group"],
    }),

    addGroup: builder.mutation({
      query: (group) => ({
        url: "/statistician/createGroup",
        method: "POST",
        body: group,
      }),
      invalidatesTags: ["group", "allItemsData"],
    }),

    //* classes related
    getClasses: builder.query({
      query: () => "/common/category/class",
      providesTags: ["class"],
    }),

    addClass: builder.mutation({
      query: (newClass) => ({
        url: "/statistician/createClass",
        method: "POST",
        body: newClass,
      }),
      invalidatesTags: ["class", "allItemsData"],
    }),

    //* subclasses related
    getSubclasses: builder.query({
      query: () => "/common/category/subclass",
      providesTags: ["subclass"],
    }),

    addSubclass: builder.mutation({
      query: (subclass) => ({
        url: "/statistician/createSubclass",
        method: "POST",
        body: subclass,
      }),
      invalidatesTags: ["subclass", "allItemsData"],
    }),

    //* items related
    getAllItemsData: builder.query({
      query: () => "/common/1/category/all",
      providesTags: ["allItemsData"],
      transformResponse: (response) => {
        return replaceItemsKey(response);
      },
    }),

    //* locations data related
    getAllLocationsData: builder.query({
      query: () => "/common/address/all",
      providesTags: ["allLocationsData"],
      transformResponse: (response) => {
        return replaceLocationsKey(response);
      },
    }),

    //* items related
    getItems: builder.query({
      query: () => "/common/category/item",
      providesTags: ["items"],
    }),


    addItem: builder.mutation({
      query: (item) => ({
        url: "/statistician/createItem",
        method: "POST",
        body: item,
      }),
      invalidatesTags: ["allItemsData"],
    }),

    editItem: builder.mutation({
      query: (item) => ({
        url: `/statistician/category/item/${item.code}`,
        method: "PUT",
        body: item.body,
      }),
      invalidatesTags: ["allItemsData"],
    }),

    addSeasonalItem: builder.mutation({
      query: (item) => ({
        url: `/datacleaner/seasonal/questionnaire/${item.questionnaireId}`,
        method: "POST",
        body: item
      }),
    }),

    removeSeasonalItem: builder.mutation({
      query: (item) => ({
        url: `/datacleaner/seasonal/remove/questionnaire/${item.questionnaireId}`,
        method: "DELETE",
        body: item
      })
    }),

    //* file upload related
    uploadFile: builder.mutation({
      query: (image) => ({
        url: "/file/image/upload",
        method: "POST",
        body: image,
      }),
    }),

    //* measurement unit related
    getMeasurements: builder.query({
      query: () => "/common/measurement",
      providesTags: ["measurement"],
    }),
    //* measurement unit related
    getUnitOfMeasure: builder.query({
      query: () => "/common/unitOfMeasure",
      providesTags: ["unitOfMeasurement"],
    }),

    //* translation related
    updateTranslation: builder.mutation({
      query: (translation) => ({
        url: `/statistician/translation/${translation.type}/${translation.code}/${translation.langCode}`,
        method: "PUT",
        body: {
          name: String(translation.updatedName),
        },
      }),
      invalidatesTags: [
        "allItemsData",
        "allLocationsData",
        "regions",
        "zones",
        "woredas",
        "branches",
        "cities",
        "marketplaces",
        "division",
        "group",
        "class",
        "subclass",
      ],
    }),

    //* regions related
    getRegions: builder.query({
      query: () => "/common/address/region",
      providesTags: ["regions"],
      transformResponse: (response) => {
        return replaceRegionKeys(response);
      },
    }),

    addRegion: builder.mutation({
      query: (region) => ({
        url: "/statistician/createRegion",
        method: "POST",
        body: region,
      }),
      invalidatesTags: ["regions", "allLocationsData"],
    }),

    //* zones related
    getZones: builder.query({
      query: () => "/common/address/zone",
      providesTags: ["zones"],
    }),

    addZone: builder.mutation({
      query: (zone) => ({
        url: "/statistician/createZone",
        method: "POST",
        body: zone,
      }),
      invalidatesTags: ["allLocationsData", "zones"],
    }),

    //* woredas related
    getWoredas: builder.query({
      query: () => "/common/address/woreda",
      providesTags: ["woredas"],
    }),

    addWoreda: builder.mutation({
      query: (woreda) => ({
        url: "/statistician/createWoreda",
        method: "POST",
        body: woreda,
      }),
      invalidatesTags: ["woredas", "allLocationsData"],
    }),

    //* branch related
    getBranches: builder.query({
      query: () => "/common/address/branch",
      providesTags: ["branches"],
    }),

    addBranch: builder.mutation({
      query: (branch) => ({
        url: "/statistician/createBranch",
        method: "POST",
        body: branch,
      }),
      invalidatesTags: ["branches", "allLocationsData"],
    }),

    //* cities related
    getCities: builder.query({
      query: () => "/common/address/city",
      providesTags: ["cities"],
    }),

    addCity: builder.mutation({
      query: (city) => ({
        url: "/statistician/createCity",
        method: "POST",
        body: city,
      }),
      invalidatesTags: ["cities", "allLocationsData"],
    }),

    //* marketplace related
    getMarketplaces: builder.query({
      query: () => "/common/address/marketplace",
      providesTags: ["marketplaces"],
    }),

    addMarketplace: builder.mutation({
      query: (marketplace) => ({
        url: "/statistician/createMarketplace",
        method: "POST",
        body: marketplace,
      }),
      invalidatesTags: ["marketplaces", "allLocationsData"],
    }),

    disableMarketplace: builder.mutation({
      query: (data) => ({
        url: `/statistician/disable/marketplace/${data.marketplaceCode}`,
        method: "PUT",
        body: data.body,
      }),
      invalidatesTags: ["marketplaces", "allLocationsData"],
    }),

    //* questionnaire related
    getAllQuestionnaires: builder.query({
      query: () => "/common/questionnaire",
      providesTags: ["questionnaire"],
      transformResponse: (response) => {
        return replaceQuestionnaireKeys(response);
      },
    }),
    getQuestionnaire: builder.query({
      query: (questionnaireId) => `/common/questionnaire/${questionnaireId}`,
      providesTags: ["questionnaireById"],
    }),
    addQuestionnaire: builder.mutation({
      query: (questionnaire) => ({
        url: "/statisticianXDirector/questionnaire",
        method: "POST",
        body: questionnaire,
      }),
      invalidatesTags: ["questionnaire", "questionnaireById"],
    }),
    editQuestionnaire: builder.mutation({
      query: (questionnaire) => ({
        url: `/statisticianXDirector/questionnaire/${questionnaire.id}`,
        method: "PUT",
        body: questionnaire.body,
      }),
      invalidatesTags: ["questionnaire", "questionnaireById"],
    }),
    deleteDraftQuestionnaire: builder.mutation({
      query: (questionnaireId) => ({
        url: `/statisticianXDirector/questionnaire/${questionnaireId}`,
        method: "DELETE",
        body: {},
      }),
      invalidatesTags: ["questionnaire", "questionnaireById"],
    }),
    approveQuestionnaire: builder.mutation({
      query: (questionnaire) => ({
        url: `/director/questionnaire/approve/${questionnaire.id}`,
        method: "PUT",
        body: questionnaire.body,
      }),
      invalidatesTags: ["questionnaire", "questionnaireById"],
    }),
    addItemsToQuestionnaire: builder.mutation({
      query: (data) => ({
        url: `/statisticianXDirector/questionnaire/${data.questionnaireId}/item`,
        method: "PUT",
        body: data.body,
      }),
      invalidatesTags: ["questionnaire", "questionnaireById"],
    }),

    //* CPI related
    getAllCPIBaskets: builder.query({
      query: () => "/statisticianXDirector/cpiBasket",
      providesTags: ["CPIBasket"],
      transformResponse: (response) => {
        return replaceCPIBasketKeys(response);
      },
    }),

    addNationalWithRegionalCPI: builder.mutation({
      query: (data) => ({
        url: `/director/cpiBasket/national`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["CPIBasket"],
    }),

    addCPIBasket: builder.mutation({
      query: (data) => ({
        url: `/director/cpiBasket/batch`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["CPIBasket"],
    }),

    getCPIBasket: builder.query({
      query: (regionId) =>
        `/statisticianXDirector/cpiBasket/${regionId === undefined ? "" : regionId
        }`,
      providesTags: ["CPIBasketByRegion"],
      transformResponse: (response) => {
        let temp = { ...response };
        // console.log(temp);
        for (const cpiData of temp.data) {
          cpiData.baseYear = getFormattedDate(cpiData.baseYear);
        }

        return temp;
      },
    }),

    addItemToCPIBasket: builder.mutation({
      query: (data) => ({
        url: `/director/cpiBasket/${data.regionCode}/item`,
        method: "PUT",
        body: data.body,
      }),
      invalidatesTags: ["CPIBasket", "CPIBasketByRegion"],
    }),

    changeItemStatusInsideCPIBasket: builder.mutation({
      query: (data) => ({
        url: `/director/cpiBasket/${data.regionCode}/item/${data.itemCode}`,
        method: "DELETE",
        body: data.body,
      }),
      invalidatesTags: ["CPIBasket", "CPIBasketByRegion"],
    }),

    importQuotations: builder.mutation({
      query: (data) => ({
        url: `/statistician/import/quotation`,
        method: "POST",

        body: data,
        timeout: 600000, // temporary fix to fix api call hangups
      }),
      // invalidatesTags: ["quotations"],
    }),

    importAddresses: builder.mutation({
      query: (data) => ({
        url: `/statistician/import/address`,
        method: "POST",
        body: data,
      }),
      // invalidatesTags: ["addresses"],
    }),

    importCategories: builder.mutation({
      query: (data) => ({
        url: `/statistician/import/category`,
        method: "POST",
        body: data,
      }),
      // invalidatesTags: ["categories"],
    }),

    importCPIBasket: builder.mutation({
      query: (data) => ({
        url: `/director/import/cpiBasket`,
        method: "POST",
        body: data,
      }),
      // invalidatesTags: ["cpiBasket"],
    }),

    importQuestionnaire: builder.mutation({
      query: (data) => ({
        url: `/director/import/questionnaire`,
        method: "POST",
        body: data,
      }),
      // invalidatesTags: ["questionnaires"],
    }),

    importCPI: builder.mutation({
      query: (data) => ({
        // url: `/director/import/cpi`,
        url: `/datacleaner/import/cpi`,
        method: "POST",
        body: data,
      }),
      // invalidatesTags: ["questionnaires"],
    }),

    //* cleaning related
    //approvedQuotations
    getApprovedQuotations: builder.query({
      query: (questionnaireId) =>
        `/datacleaner/questionnaire/${questionnaireId}/quotation`,
      providesTags: ["approvedQuotations"],
      transformResponse: transformApprovedQuotations,
    }),

    getCleanedQuestionnaires: builder.query({
      query: () => "/common/questionnaire",
      providesTags: ["questionnaire"],
      transformResponse: (response) => {
        let temp = { ...response };
        // console.log(temp);
        let questionnaireData = {
          data: [],
        };

        for (const questionnaire of temp.data) {
          // if (questionnaire.quotationsStatus === 'CLEANED') {
          questionnaireData.data.push(questionnaire);
          // }

          questionnaire.status = questionnaire.quotationsStatus;
        }
        //* sort the data based on its created at date
        questionnaireData.data.sort(
          (objA, objB) =>
            Date.parse(objB.createdAt) - Date.parse(objA.createdAt)
        );

        return questionnaireData;
        // return temp;
      },
    }),
    interpolateQuestionnaire: builder.mutation({
      query: (data) => ({
        url: `/datacleaner/interpolate/questionnaire/${data.questionnaireId}`,
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["questionnaire"],
    }),
    makeCleanQuotation: builder.mutation({
      query: (data) => ({
        url: `/datacleaner/clean/questionnaire/${data.questionnaireId}`,
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["questionnaire"],
    }),
    getQuestionnaireStatus: builder.query({
      query: (data) =>
        `/datacleaner/questionnaire/${data.questionnaireId}/status${data.regionCode ? `?regionCode=${data.regionCode}` : ""
        }`,
      providesTags: ["questionnaireStatus"],
      transformResponse: (response) => {
        let temp = { ...response };
        //
        return temp;
      },
    }),
    imputePrices: builder.mutation({
      query: (data) => ({
        url: `/datacleaner/questionnaire/${data.questionnaireId}/previous-quotations?regionCode=${data.regionCode}`,
        method: "POST",
        body: {},
      }),
    }),

    getSeasonalGeomean: builder.mutation({
      query: (data) => ({
        url: `/datacleaner/geomean/questionnaire/${data.questionnaireId}`,
        method: "GET",
        transformResponse: (response) => {
          let temp = { ...response };
          //
          return temp;
        },
      })
    }),
    computeGeomean: builder.mutation({
      query: (data) => ({
        url: `/datacleaner/geomean/questionnaire/${data.questionnaireId}/region/${data.regionCode}`,
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["questionnaireStatus", "questionnaire"],
    }),
    computeNationalGeomean: builder.mutation({
      query: (data) => ({
        url: `/datacleaner/geomean/questionnaire/${data.questionnaireId}`,
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["questionnaireStatus", "questionnaire"],
    }),
    computeCpi: builder.mutation({
      query: (data) => ({
        url: `/datacleaner/cpi/questionnaire/${data.questionnaireId}/region/${data.regionCode}`,
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["questionnaireStatus", "questionnaire"],
    }),
    computeInflation: builder.mutation({
      query: (data) => ({
        url: `/datacleaner/inflation/questionnaire/${data.questionnaireId}/region/${data.regionCode}`,
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["questionnaireStatus", "questionnaire"],
    }),
    computeNationalCpi: builder.mutation({
      query: (data) => ({
        url: `/datacleaner/national/questionnaire/${data.questionnaireId}/calculate?regionCode=${data.regionCode}`,
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["questionnaireStatus", "questionnaire"],
    }),
    getUserDetails: builder.query({
      query: () => `/user/status`,
      providesTags: ["userStatus"],
      transformResponse: (response) => {
        let temp = { ...response };
        //
        return temp;
      },
    }),
    questionnairePreview: builder.query({
      query: (data) =>
        `/datacleaner/questionnaire/${data.questionnaireId}/preview`,
      providesTags: ["questionnairePreview"],
      transformResponse: (response) => {
        return questPrevParser(response);
      },
    }),
    addQuestionnaireDescription: builder.mutation({
      query: (data) => ({
        url: `/director/questionnaire/${data.questionnaireId}/description`,
        method: "PUT",
        body: data.body,
      }),
      invalidatesTags: ["questionnairePreview"],
    }),
    publishQuestionnaire: builder.mutation({
      query: (data) => ({
        url: `/director/publish/questionnaire/${data.questionnaireId}`,
        method: "POST",
        body: {},
      }),
      invalidatesTags: ["questionnairePreview"],
    }),
    syncDataWithPresentationServer: builder.mutation({
      query: () => ({
        url: `/director/sync/data`,
        method: "POST",
        body: {},
      }),
      // invalidatesTags: ["questionnairePreview"],
    }),

    //Delete categories
    deleteDivision: builder.mutation({
      query: (divisionCode) => ({
        url: `/statistician/division/${divisionCode}`,
        method: "DELETE",
        body: {},
      }),
      invalidatesTags: ["allItemsData"],
    }),

    deleteGroup: builder.mutation({
      query: (groupCode) => ({
        url: `/statistician/group/${groupCode}`,
        method: "DELETE",
        body: {},
      }),
      invalidatesTags: ["allItemsData"],
    }),

    deleteClass: builder.mutation({
      query: (classCode) => ({
        url: `/statistician/class/${classCode}`,
        method: "DELETE",
        body: {},
      }),
      invalidatesTags: ["allItemsData"],
    }),

    deleteSubclass: builder.mutation({
      query: (subclassCode) => ({
        url: `/statistician/subclass/${subclassCode}`,
        method: "DELETE",
        body: {},
      }),
      invalidatesTags: ["allItemsData"],
    }),

    deleteItem: builder.mutation({
      query: (itemCode) => ({
        url: `/statistician/item/${itemCode}`,
        method: "DELETE",
        body: {},
      }),
      invalidatesTags: ["allItemsData"],
    }),

    //delete locations
    deleteRegion: builder.mutation({
      query: (regionCode) => ({
        url: `/statistician/region/${regionCode}`,
        method: "DELETE",
        body: {},
      }),
      invalidatesTags: ["allLocationsData"],
    }),

    deleteZone: builder.mutation({
      query: (zoneCode) => ({
        url: `/statistician/zone/${zoneCode}`,
        method: "DELETE",
        body: {},
      }),
      invalidatesTags: ["allLocationsData"],
    }),

    deleteWoreda: builder.mutation({
      query: (woredaCode) => ({
        url: `/statistician/woreda/${woredaCode}`,
        method: "DELETE",
        body: {},
      }),
      invalidatesTags: ["allLocationsData"],
    }),

    deleteBranch: builder.mutation({
      query: (branchCode) => ({
        url: `/statistician/branch/${branchCode}`,
        method: "DELETE",
        body: {},
      }),
      invalidatesTags: ["allLocationsData", "branches"],
    }),

    deleteMarketplace: builder.mutation({
      query: (marketplaceCode) => ({
        url: `/statistician/marketplace/${marketplaceCode}`,
        method: "DELETE",
        body: {},
      }),
      invalidatesTags: ["allLocationsData"],
    }),
  }),
});

export const {
  //* user related
  useGetUsersQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useDisableUserMutation,
  useGetUserByIdQuery,
  useChangeLanguageMutation,

  //* language related
  useGetLanguagesQuery,
  useAddLanguageMutation,

  //* division related
  useGetDivisionsQuery,
  useAddDivisionMutation,

  //* groups related
  useGetGroupsQuery,
  useAddGroupMutation,

  //* class related
  useGetClassesQuery,
  useAddClassMutation,

  //* subclass related
  useGetSubclassesQuery,
  useAddSubclassMutation,

  //* allItemsData related
  useGetAllItemsDataQuery,

  //* allLocationsData related
  useGetAllLocationsDataQuery,

  //* items related
  useGetItemsQuery,
  useAddItemMutation,
  useEditItemMutation,
  useAddSeasonalItemMutation,
  useRemoveSeasonalItemMutation,

  //* file upload related
  useUploadFileMutation,

  //* measurement unit related
  useGetMeasurementsQuery,
  useGetUnitOfMeasureQuery,

  //* translations related
  useUpdateTranslationMutation,

  //* regions related
  useGetRegionsQuery,
  useAddRegionMutation,

  //* zones related
  useGetZonesQuery,
  useAddZoneMutation,

  //* woredas related
  useGetWoredasQuery,
  useAddWoredaMutation,

  //* branch related
  useGetBranchesQuery,
  useAddBranchMutation,

  //* cities related
  useGetCitiesQuery,
  useAddCityMutation,

  //* marketplace related
  useGetMarketplacesQuery,
  useAddMarketplaceMutation,
  useDisableMarketplaceMutation,

  //* quesitonnaire related
  useGetAllQuestionnairesQuery,
  useGetQuestionnaireQuery,
  useAddQuestionnaireMutation,
  useEditQuestionnaireMutation,
  useDeleteDraftQuestionnaireMutation,
  useApproveQuestionnaireMutation,
  useAddItemsToQuestionnaireMutation,

  //* CPIBasket related
  useGetAllCPIBasketsQuery,
  useAddCPIBasketMutation,
  useAddNationalWithRegionalCPIMutation,
  useGetCPIBasketQuery,
  useAddItemToCPIBasketMutation,
  useChangeItemStatusInsideCPIBasketMutation,

  //* Import related
  useImportQuotationsMutation,
  useImportAddressesMutation,
  useImportCategoriesMutation,
  useImportCPIBasketMutation,
  useImportQuestionnaireMutation,
  useImportCPIMutation,

  //* cleaning related
  useGetCleanedQuotationsQuery,
  useGetCleanedQuestionnairesQuery,
  useInterpolateQuestionnaireMutation,
  useMakeCleanQuotationMutation,
  useGetQuestionnaireStatusQuery,
  useImputePricesMutation,
  useGetSeasonalGeomeanMutation,
  useComputeGeomeanMutation,
  useComputeNationalGeomeanMutation,
  useComputeCpiMutation,
  useComputeInflationMutation,
  useGetUserDetailsQuery,
  useComputeNationalCpiMutation,
  useQuestionnairePreviewQuery,
  useAddQuestionnaireDescriptionMutation,
  usePublishQuestionnaireMutation,
  useSyncDataWithPresentationServerMutation,

  //* Delete categories
  useDeleteDivisionMutation,
  useDeleteGroupMutation,
  useDeleteClassMutation,
  useDeleteSubclassMutation,
  useDeleteItemMutation,

  //* Delete locations
  useDeleteRegionMutation,
  useDeleteZoneMutation,
  useDeleteWoredaMutation,
  useDeleteBranchMutation,
  useDeleteMarketplaceMutation,
} = apiSlice;
