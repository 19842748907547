import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    questionnaireToBeUpdatedData: {},
}

const questionnaireSlice = createSlice({
    name: 'questionnaire',
    initialState,
    reducers: {
        setQuestionnaireToBeUpdatedData: (state, action) =>{
            const data = action.payload;
            state.questionnaireToBeUpdatedData = {...data};
        },
    }
});

export const {setQuestionnaireToBeUpdatedData} = questionnaireSlice.actions;
export default questionnaireSlice.reducer;

