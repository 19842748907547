import React, { useEffect, useState, Suspense, lazy } from "react";
import Sidebar from "components/sidebar/Sidebar";
import TopNavbar from "components/TopNavbar";
import Setting from "../setting/Setting";
import { Routes, Route } from "react-router-dom";
import { getWindowSize } from "helpers/window-size";
import useAuth from "hooks/useAuth";
import { useNavigate } from "react-router-dom";
import userRoles from "components/user_roles";
import { RequireAuthWithRole } from "components/RequireAuth";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";
// import './questionnaire_clean_styles.css';
import "../questionnaire/questionnaire_clean_styles.css";

//!change
const ItemAdd = lazy(() => import("../item/item_add"));
const Item = lazy(() => import("pages/item/Item"));
const Questionnaire = lazy(() => import("../questionnaire/Questionnaire"));
const QuestionnaireAdd = lazy(() =>
  import("../questionnaire/Questionnaire_add")
);
const Users = lazy(() => import("pages/users/Users"));
const Languages = lazy(() => import("pages/languages/Languages"));
const Locations = lazy(() => import("pages/locations/locations"));
const CPIBasket = lazy(() => import("pages/questionnaire/CPI_Basket"));
const CPIBasketAdd = lazy(() => import("pages/questionnaire/CPI_Basket_add"));
const QuestionnaireDisplay = lazy(() =>
  import("../questionnaire/questionnaire_display")
);
const QuestionnaireClean = lazy(() =>
  import("../questionnaire/questionnaire_clean")
);
const QuestionnairePreview = lazy(() =>
  import("../questionnaire/questionnaire_preview")
);
const QuestionnaireApprovedDisplay = lazy(() =>
  import("../questionnaire/questionnaire_approved_display")
);
const QuestionnaireCleanOriginal = lazy(() =>
  import("../questionnaire/questionnaire_clean_original")
);
const ImportCleanQuotation = lazy(() =>
  import("pages/quotation/ImportCleanQuotation")
);
const ImportCategories = lazy(() => import("pages/category/importCategories"));
const ImportAddresses = lazy(() => import("pages/locations/ImportAddresses"));
const ImportCPIBasket = lazy(() => import("pages/cpi/ImportCPIBaskets"));
const ImportQuestionnaire = lazy(() =>
  import("pages/questionnaire/ImportQuestionnaire")
);
const ImportCPI = lazy(() => import("pages/cpi/ImportCPI"));
//
// const Quotation = lazy(() => import('pages/quotation/Quotation'));

//
// import ItemAdd from "../item/item_add";
// import Item from "pages/item/Item";
// import Questionnaire from "../questionnaire/Questionnaire";
// import QuestionnaireAdd from "../questionnaire/Questionnaire_add";
// import Users from "pages/users/Users";
// import Languages from "pages/languages/Languages";
// import Locations from "pages/locations/locations";
// import CPIBasket from "pages/questionnaire/CPI_Basket";
// import CPIBasketAdd from "pages/questionnaire/CPI_Basket_add";
// import QuestionnaireDisplay from "../questionnaire/questionnaire_display";
// import QuestionnaireClean from "../questionnaire/questionnaire_clean";
// import QuestionnairePreview from '../questionnaire/questionnaire_preview';
// import QuestionnaireApprovedDisplay from '../questionnaire/questionnaire_approved_display';
// import QuestionnaireCleanOriginal from '../questionnaire/questionnaire_clean_original';
// //
// import ImportCleanQuotation from "pages/quotation/ImportCleanQuotation";
// import ImportCategories from "pages/category/importCategories";
// import ImportAddresses from "pages/locations/ImportAddresses";
// import ImportCPIBasket from "pages/cpi/ImportCPIBaskets";
// import ImportQuestionnaire from "pages/questionnaire/ImportQuestionnaire";
// import ImportCPI from "pages/cpi/ImportCPI";
//
// // import Quotation from "pages/quotation/Quotation";
// // import QuestionnaireDisplay from '../questionnaire/questionnaire_display';
// // import QuestionnaireClean from '../questionnaire/questionnaire_clean';

//! the place where you render based on the user role it admin display user management page else other...

function Home() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const { auth } = useAuth();
  const location = useLocation();
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/home/*") {
      if (auth.userRole === userRoles.STATISTICIAN) {
        navigate("/home/item", { replace: true });
      }
      else if (auth.userRole === userRoles.DIRECTORATE_DIRECTOR) {
        navigate("/home/questionnaire", { replace: true });
      }
      else if (auth.userRole === userRoles.DEPUTY_DIRECTOR_GENERAL) {
        navigate("/home/questionnaire", { replace: true });
      }
      else if (auth.userRole === userRoles.DIRECTOR_GENERAL) {
        navigate("/home/questionnaire", { replace: true });
      }
      else if (auth.userRole === userRoles.ADMIN) {
        navigate("/home/users", { replace: true });
      } else if (auth.userRole === userRoles.DATA_CLEANER) {
        navigate("/home/display", { replace: true });
      } else if (!localStorage.getItem("isLoggedIn")) {
        console.log("first");
        navigate("/login", { replace: true });
      } else {
        console.log("second");
        navigate("/login", { replace: true });
      }
    }

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [auth.userRole, location.pathname, navigate]);

  const getContentStyle = () => {
    if (isSidebarVisible) {
      //! for when sidebar is visible
      return {
        marginLeft: auth.userRole !== userRoles.SUPER_ADMIN ? "270px" : "20px",
        maxWidth:
          auth.userRole !== userRoles.SUPER_ADMIN
            ? windowSize.innerWidth * 0.8
            : windowSize.innerWidth,
      };
    } else {
      //! for when sidebar is not visible
      return {
        maxWidth: windowSize.innerWidth * 1,
        marginLeft: "35px",
      };
    }
  };

  return (
    <div>
      <div
        style={{
          height: "100vh",
          display: "flex",
          backgroundColor: "#eaeaea77",
          flexDirection: "column",
        }}
      >
        <TopNavbar />

        <div style={{ marginTop: "60px" }}>
          {/* change this */}
          {/* {auth.userRole !== userRoles.SUPER_ADMIN && isSidebarVisible && <Sidebar isVisible={}/>} */}
          {/* <Sidebar isVisible={auth.userRole !== userRoles.SUPER_ADMIN && isSidebarVisible} handleToggleChange={()=> {
            setIsSidebarVisible(!isSidebarVisible);
          }}/> */}

          {/* new change */}
          <Sidebar
            isVisible={isSidebarVisible}
            handleToggleChange={() => {
              setIsSidebarVisible(!isSidebarVisible);
            }}
          />

          {/* <Sidebar isVisible={true}/> */}

          <div
            style={{
              ...getContentStyle(),

              //!defaults
              overflowY: "auto",
              maxHeight: windowSize.innerHeight * 0.92,
              // marginLeft: '50px',
              marginRight: "20px",
            }}
          >
            <Suspense fallback={<Spin size="medium" />}>
              <Routes>
                <Route
                  element={
                    <RequireAuthWithRole
                      allowedRole={[userRoles.STATISTICIAN]}
                    />
                  }
                >
                  <Route path="item" element={<Item />} />
                  <Route path="item/add" element={<ItemAdd />} />
                </Route>

                <Route
                  element={
                    <RequireAuthWithRole
                      allowedRole={[userRoles.ADMIN, userRoles.STATISTICIAN]}
                    />
                  }
                >
                  <Route path="locations" element={<Locations />} />
                </Route>

                <Route
                  element={
                    <RequireAuthWithRole
                      allowedRole={[
                        userRoles.DIRECTORATE_DIRECTOR,
                        userRoles.STATISTICIAN,
                      ]}
                    />
                  }
                >
                  <Route path="cpi" element={<CPIBasket />} />
                  <Route path="/cpi/add" element={<CPIBasketAdd />} />
                </Route>

                <Route
                  element={
                    <RequireAuthWithRole
                      allowedRole={[
                        userRoles.DATA_CLEANER,
                        userRoles.STATISTICIAN,
                        userRoles.DIRECTORATE_DIRECTOR,
                        userRoles.DEPUTY_DIRECTOR_GENERAL,
                        userRoles.DIRECTOR_GENERAL,
                      ]}
                    />
                  }
                >
                  <Route path="display" element={<QuestionnaireDisplay />} />
                  <Route
                    path="display/clean"
                    element={<QuestionnaireClean />}
                  />
                  <Route
                    path="display/clean/original"
                    element={<QuestionnaireCleanOriginal />}
                  />
                  {/* <Route path="display/directorc" element={<QuestionnaireCleanDirector />} /> */}
                  <Route
                    path="display/approved"
                    element={<QuestionnaireApprovedDisplay />}
                  />
                  <Route
                    path="display/clean/preview"
                    element={<QuestionnairePreview />}
                  />
                </Route>

                <Route
                  element={
                    <RequireAuthWithRole
                      allowedRole={[
                        userRoles.STATISTICIAN,
                        userRoles.DIRECTORATE_DIRECTOR,
                        userRoles.DEPUTY_DIRECTOR_GENERAL,
                        userRoles.DIRECTOR_GENERAL,
                        userRoles.DATA_CLEANER,
                      ]}
                    />
                  }
                >
                  <Route path="questionnaire" element={<Questionnaire />} />
                  {/* <Route path="quotation" element={<Quotation />} /> */}

                  <Route
                    path="questionnaire/add"
                    element={<QuestionnaireAdd />}
                  />
                  <Route
                    path="import/quotation"
                    element={<ImportCleanQuotation />}
                  />
                  <Route
                    path="import/categories"
                    element={<ImportCategories />}
                  />
                  <Route
                    path="import/addresses"
                    element={<ImportAddresses />}
                  />
                  <Route path="import/basket" element={<ImportCPIBasket />} />
                  <Route
                    path="import/questionnaire"
                    element={<ImportQuestionnaire />}
                  />
                  <Route path="import/cpi" element={<ImportCPI />} />
                  {/* <Route path="questionnaire/cpi" element={<CPIBasket />} /> */}
                  {/* <Route
                    path="questionnaire/cpi/add"
                    element={<CPIBasketAdd />}
                  /> */}
                </Route>

                <Route
                  element={
                    <RequireAuthWithRole allowedRole={[userRoles.ADMIN]} />
                  }
                >
                  <Route path="users" element={<Users />} />
                  <Route path="languages" element={<Languages />} />
                </Route>

                <Route path="setting" element={<Setting />} />
              </Routes>
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
