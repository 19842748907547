import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    locations: [],
    regions: [],
    zones: [],
    woredas: [],
    branches: [],
    cities: [],
}

const locationsSlice = createSlice({
    name: 'locations',
    initialState,
    reducers: {
        setLocations: (state, action) =>{
            //TODO: Inside the zones check if regionId matches with any region id inside the regions list
        
            //TODO: If any match found add a key called children with a list value to the region object and add the zone inside it

        },
        clearLocations: (state, action) =>{
            state.locations = [];
        },
        setRegions: (state, action) =>{
            const newRegionsData = action.payload;
            for(let i = 0; i < newRegionsData.length; i++){
                newRegionsData[i].name = newRegionsData[i]['translation'][0].name;
                newRegionsData[i].key = newRegionsData[i].id;
            }
            state.regions = [...newRegionsData];
        },
        clearRegions: (state, action) =>{
            state.regions = [];
        },
        setZones: (state, action) =>{
            const newZonesData = action.payload;
            for(let i = 0; i < newZonesData.length; i++){
                newZonesData[i].name = newZonesData[i]['translation'][0].name;
                newZonesData[i].key = newZonesData[i].id;
            }
            state.zones = [...newZonesData];
        },
        clearZones: (state, action)=>{
            state.zones = [];
        },
        setWoredas: (state, action) =>{
            const newWoredasData = action.payload;
            for(let i = 0; i < newWoredasData.length; i++){
                newWoredasData[i].name = newWoredasData[i]['translation'][0].name;
                newWoredasData[i].key = newWoredasData[i].id;
            }
            state.woredas = [...newWoredasData];
        },
        clearWoredas: (state, action) =>{
            state.woredas = [];
        },
        setBranches: (state, action) =>{
            const newBranchesData = action.payload;
            for(let i = 0; i < newBranchesData.length; i++){
                newBranchesData[i].name = newBranchesData[i]['translation'][0].name;
                newBranchesData[i].key = newBranchesData[i].id;
            }
            state.branches = [...newBranchesData];
        },
        clearBranches: (state, action) =>{
            state.branches = [];
        },
        setCities: (state, action) => {
            const newCitiesData = action.payload;
            for(let i = 0; i < newCitiesData.length; i++){
                newCitiesData[i].name = newCitiesData[i]['translation'][0].name;
                newCitiesData[i].key = newCitiesData[i].id;
            }
            state.cities = [...newCitiesData];
        }, 
        clearCities: (state, action) => {
            state.cities = [];
        },

    }
});

export const { setLocations, setRegions, clearLocations, clearRegions, setZones, clearZones, setWoredas, clearWoredas, setBranches, clearBranches, setCities, clearCities } = locationsSlice.actions;
export default locationsSlice.reducer;