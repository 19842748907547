import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    languages: []
}

export const languagesSlice = createSlice({
    name: 'languages',
    initialState,
    reducers: {
        setLanguages: (state, action) =>{
            const languagesPayload = action.payload;

            for(let i = 0; i < languagesPayload.length; i++){
                languagesPayload[i].key = languagesPayload[i].code;
                state.languages.push(languagesPayload[i]);
            }
        },
        clearLanguages: (state, action) =>{
            state.languages = [];
        },
        removeLanguage: (state, action)=>{
            const languageToRemove = action.payload;
        }
    }
});

export const { setLanguages, clearLanguages, removeLanguage } = languagesSlice.actions;
export default languagesSlice.reducer;