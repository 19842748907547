import divCodes from "../../../constants/codes";
import { names } from "../../../constants/names";

const table7Parser = (responseDataFromApi) => {
  let table7Data = {
    groups: [],
    data: [],
  };

  let table7ChartData = [];

  //! table data
  for (let questData of responseDataFromApi) {
    let newRow = {};
    newRow.date = questData.startDate;
    //! food and non alcoholic
    for (let nationalDivisionCpi of questData.nationalDivisionCPIs) {
      let divisionCode = nationalDivisionCpi.division.code;
      if (divisionCode === divCodes.foodDivCode) {
        //food division
        newRow.foodAndNonAlcoholic = nationalDivisionCpi.cpi.toFixed(2);
      }
    }
    //! food and non alcoholic groups
    for (let foodGroupCpi of questData.nationalGroupCPIs) {
      const groupName = foodGroupCpi.group.translation[0].name;
      if (!table7Data.groups.includes(groupName)) {
        table7Data.groups.push(groupName);
      }
      newRow[groupName.replace(/ /g, "_")] = foodGroupCpi.cpi.toFixed(2);
      // nonFoodRow[divisionName.replace(/ /g, "_")]
    }

    table7Data.data.push(newRow);
  }

  //! chart data
  for (let tableRow of table7Data.data) {
    //Food and non-alcoholic cpi
    table7ChartData.push({
      date: tableRow.date,
      name: names.foodCategoryName,
      value: tableRow.foodAndNonAlcoholic,
    });

    for (let foodGroup of table7Data.groups) {
      table7ChartData.push({
        date: tableRow.date,
        name: foodGroup,
        value: tableRow[foodGroup.replace(/ /g, "_")],
      });
    }
  }

  // console.log(table7Data);
  // console.log(table7ChartData);
  return {
    table7ChartData,
    table7Data,
  };
};

export default table7Parser;
