import Login from 'pages/auth/Login';
import 'antd/dist/antd.min.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import Home from 'pages/home/Home';
import {useState, useEffect} from 'react';
import ForgotPass from 'pages/auth/forgot_pass';


function App() {
    const { setAuth } = useAuth();
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);
    
    useEffect(()=>{
        const isUserLoggedIn = localStorage.getItem('isLoggedIn');
        if(isUserLoggedIn) {
            setLoggedIn(true);
            setAuth(JSON.parse(localStorage.getItem('user')));
        }
        setLoading(false);
    },[]);
    {/* nest other components inside the layout component that is attached to the '/' root path */}

    if(loading){
        return (<></>);
    }

    return (
        <Routes>
            <Route path='/*' element={loggedIn ? <Navigate to={'/home/*'} replace={true}/> : <Navigate to={'/login'} replace={true}/>} />
            <Route path='/login' element={<Login/>}/>
            <Route path='/forgot' element={<ForgotPass/>}/>
            <Route path='/home/*' element={<Home/>}/>
        </Routes>
    );
}


export default App;