import divCodes from "../../../constants/codes";
import { names } from "../../../constants/names";
import {
  getFormattedDateFromDateObject,
  getFormattedDateByFormat,
  getFormattedDate,
} from "../../../helpers/date_format";
const table4Parser = (responseDataFromApi) => {
  /*
        {
            division: [],
            regions: [
                { name: 'Addis Ababa', code: 1 },
                { name: 'Dire Dawa', code: 2 },
            ],
            data: {
                / regionCode
                '1': [],
                '2': [],
            }
        }
    */

  let table4Data = {
    divisions: [],
    regions: [],
    data: {},
  };

  let table4ChartData = {};

  let startDates = [];

  for (let qData of responseDataFromApi) {
    startDates.push(qData.startDate);
    for (let divCpi of qData.divisionCPIs) {
      // list regions
      if (
        table4Data.regions.find(
          (region) => region.code === divCpi.region.code
        ) === undefined
      ) {
        let newRegion = {
          name: divCpi.region.translation[0].name,
          code: divCpi.region.code,
        };
        table4Data.regions.push(newRegion);
      }
    }
  }

  let sortedDataByRegion = {};

  for (let region of table4Data.regions) {
    if (sortedDataByRegion[region] === undefined) {
      sortedDataByRegion[region.code] = {};
      table4Data.data[region.code] = {
        divisions: [],
        data: [],
      };
      table4ChartData[region.code] = [];
    }
  }

  for (let startDate of startDates) {
    for (let region of table4Data.regions) {
      sortedDataByRegion[region.code][startDate] = [];
    }
  }

  for (let qData of responseDataFromApi) {
    for (let divCpi of qData.divisionCPIs) {
      let dData = { ...divCpi, startDate: qData.startDate };
      let natGenCpi = qData.generalCPIs.find(
        (gCpi) => gCpi.region.code === divCpi.regionCode
      );

      dData.nationalGeneralCpi = natGenCpi.cpi;
      dData.realNationalGeneralCpi = qData.nationalGeneralCPI.cpi;

      sortedDataByRegion[divCpi.regionCode][dData.startDate].push(dData);
    }
  }

  // {
  //     date: '2022-12-01T11:41:33.000Z',
  //     nationalGeneralCPI: 250,
  //     nonFoodDivisions: [ [Object], [Object] ],
  //     Division_1: 99,
  //     foodAndNonAlcoholic: 1823,
  //     Division_2: 357,
  //     nonFoodIndex: 228
  // }

  //
  // {
  //   cpi: 1440,
  //   divisionCode: 3,
  //   questionnaireId: 12,
  //   regionCode: 2,
  //   nationalCPIBasketId: 3,
  //   updatedAt: null,
  //   createdAt: '2022-12-26T07:28:04.415Z',
  //   division: [Object],
  //   region: [Object],
  //   startDate: '2022-01-01T11:41:33.000Z'
  // }
  // console.log(sortedDataByRegion[1]);

  Object.keys(sortedDataByRegion).forEach((regionCode) => {
    Object.keys(sortedDataByRegion[regionCode]).forEach((startDate) => {
      let newRow = {
        nonFoodDivisions: [],
        // foodAndNonAlcoholic: ,
        // nonFoodIndex: ,
      };

      for (let divCpiData of sortedDataByRegion[regionCode][startDate]) {
        newRow.date = getFormattedDate(divCpiData.startDate);
        newRow.nationalGeneralCPI = divCpiData.nationalGeneralCpi;
        newRow.realNationalGeneralCpi =
          divCpiData.realNationalGeneralCpi.toFixed(2);

        let divisionName = divCpiData.division.translation[0].name;
        let divisionCode = divCpiData.division.code;

        if (divisionCode === divCodes.foodDivCode) {
          newRow.foodAndNonAlcoholic = divCpiData.cpi;
        } else {
          //non-food division
          let nonFoodRow = {};
          nonFoodRow.divName = divisionName;
          nonFoodRow[divisionName.replace(/ /g, "_")] = divCpiData.cpi;
          newRow[divisionName.replace(/ /g, "_")] = divCpiData.cpi;
          newRow.nonFoodDivisions.push(nonFoodRow);
        }

        //! non-food index
        let nonFoodIndexSum = 0;
        for (let nonFoodDivision of newRow.nonFoodDivisions) {
          let nonFoodDivisionNumber = Number(
            nonFoodDivision[nonFoodDivision.divName.replace(/ /g, "_")]
          );
          nonFoodIndexSum = nonFoodIndexSum + nonFoodDivisionNumber;
          // nonFoodIndexSum = nonFoodIndexSum + nonFoodDivision[nonFoodDivision.divName.replace(/ /g, "_")];
          //! for division names
          if (!table4Data.divisions.includes(nonFoodDivision.divName)) {
            table4Data.divisions.push(nonFoodDivision.divName);
          }
        }

        // console.log('nonFoodDivisions: ', newRow.nonFoodDivisions);
        // newRow.nonFoodIndex = nonFoodIndexSum / newRow.nonFoodDivisions.length;
        newRow.nonFoodIndex = (
          nonFoodIndexSum / newRow.nonFoodDivisions.length
        ).toFixed(2);
      }

      table4Data.data[regionCode].data.push(newRow);
      table4Data.data[regionCode].divisions = [...table4Data.divisions];
      if (table4Data.firstRegionCode === undefined) {
        table4Data.firstRegionCode = regionCode;
      }
    });
  });

  //! chart data
  Object.keys(table4Data.data).forEach((regionCode) => {
    for (let tableRow of table4Data.data[regionCode].data) {
      //realNationalGeneralCpi
      table4ChartData[regionCode].push({
        date: tableRow.date,
        name: "National General Index",
        value: tableRow.realNationalGeneralCpi,
      });

      //generalCpi
      table4ChartData[regionCode].push({
        date: tableRow.date,
        name: "General Index",
        value: tableRow.nationalGeneralCPI,
      });

      //foodCpi
      table4ChartData[regionCode].push({
        date: tableRow.date,
        name: names.foodCategoryName,
        value: tableRow.foodAndNonAlcoholic,
      });

      //non food cpi
      table4ChartData[regionCode].push({
        date: tableRow.date,
        name: "Non-Food Index",
        value: tableRow.nonFoodIndex,
      });

      for (let nonFoodDiv of table4Data.divisions) {
        table4ChartData[regionCode].push({
          date: tableRow.date,
          name: nonFoodDiv,
          value: tableRow[nonFoodDiv.replace(/ /g, "_")],
        });
      }
    }
  });

  // console.log(table4Data);
  // console.log(table4ChartData);

  return {
    table4ChartData,
    table4Data,
  };
};

export default table4Parser;
