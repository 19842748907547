import { nationalWeights } from "constants/weights";
import divCodes from "../../../constants/codes";
import { names } from "../../../constants/names";

const table2Parser = (responseDataFromApi) => {
  let table2ChartData = [];

  for (let qData of responseDataFromApi) {
    let qChartData = [];
    let nonFoodInflation = [];

    //* Food inflation
    for (let natDivInflation of qData.nationalDivisionInflations) {
      // console.log("foodInflations:", natDivInflation);
      let divCode = natDivInflation.division.code;
      if (divCode === divCodes.foodDivCode) {
        let chartData = {};

        chartData["date"] = qData.startDate;
        chartData["name"] = "Food Inflation";
        chartData["value"] = natDivInflation.yearOnYear.toFixed(2);

        qChartData.push(chartData);
      } else {
        // : 'Non-Food Inflation',
        nonFoodInflation.push(natDivInflation.yearOnYear);
      }
    }

    //* non-food inflation
    let nonFoodInfSum = 0;
    // let nonFoodDivCode;

    // Find the corresponding weight for the division code
    // let nonFoodDivisionWeight =
    //   nationalWeights[0].divisions
    //   .filter((el)=>el.divisionCode!== 1)
    //   .find(
    //     (division) =>
    //     division.weight
    //   ) || 0;
    // nonFoodDivCode = nonFoodDivisionWeight
    for (let nonFData of nonFoodInflation) {
      nonFoodInfSum = nonFoodInfSum + nonFData;
    }

    let nonFoodInflationData = {
      date: qData.startDate,
      name: "Non-Food Inflation",
      value: Number((nonFoodInfSum / nonFoodInflation.length).toFixed(2)),
    };

    qChartData.push(nonFoodInflationData);

    //* general inflation
    let sum = 0;
    for (let chartItem of qChartData) {
      sum = sum + chartItem.value;
    }
    let generalInflation = sum / qChartData.length;

    let generalInfData = {
      date: qData.startDate,
      name: "General Inflation",
      // value: Number(generalInflation.toFixed(2)),
      value: qData.nationalGeneralInflation.yearOnYear.toFixed(2),
    };

    qChartData.push(generalInfData);
    table2ChartData = [...table2ChartData, ...qChartData];
  }

  //sort all data by date
  table2ChartData.sort((a, b) => new Date(a.date) - new Date(b.date));

  // table 1 data
  const table2Data = [];
  let table2Dates = [];
  for (let table2ChartD of table2ChartData) {
    if (!table2Dates.includes(table2ChartD.date)) {
      table2Dates.push(table2ChartD.date);
    }
  }

  for (let table2Date of table2Dates) {
    let table2RowData = {
      date: table2Date,
      // foodInflation: ,
      // nonFoodInflation: ,
      // generalInflation: ,
    };
    for (let table2ChartD of table2ChartData) {
      if (table2ChartD.date === table2Date) {
        table2RowData[`${table2ChartD.name.replace(" ", "_")}`] =
          table2ChartD.value;
      }
    }

    table2Data.push(table2RowData);
  }

  return {
    table2ChartData,
    table2Data,
  };
};

export default table2Parser;
