import divCodes from "../../../constants/codes";
import { names } from "../../../constants/names";

const table1Parser = (responseDataFromApi) => {
  let table1ChartData = [];

  for (let qData of responseDataFromApi) {
    let qChartData = [];
    let nonFoodInflation = [];

    //* Food inflation
    for (let natDivInflation of qData.nationalDivisionInflations) {
      let divCode = natDivInflation.division.code;
      if (divCode === divCodes.foodDivCode) {
        let chartData = {};

        chartData["date"] = qData.startDate;
        chartData["name"] = "Food Inflation";
        chartData["value"] = natDivInflation.movingAverage.toFixed(2);

        qChartData.push(chartData);
      } else {
        // : 'Non-Food Inflation',
        nonFoodInflation.push(natDivInflation.movingAverage);
      }
    }

    //* non-food inflation
    let nonFoodInfSum = 0;
    for (let nonFData of nonFoodInflation) {
      nonFoodInfSum = nonFoodInfSum + nonFData;
    }

    let nonFoodInflationData = {
      date: qData.startDate,
      name: "Non-Food Inflation",
      value: Number((nonFoodInfSum / nonFoodInflation.length).toFixed(2)),
    };

    qChartData.push(nonFoodInflationData);

    //* general inflation
    let sum = 0;
    for (let chartItem of qChartData) {
      sum = sum + chartItem.value;
    }
    let generalInflation = sum / qChartData.length;

    let generalInfData = {
      date: qData.startDate,
      name: "General Inflation",
      // value: Number(generalInflation.toFixed(2)),
      value: qData.nationalGeneralInflation.movingAverage.toFixed(2),
    };

    qChartData.push(generalInfData);
    table1ChartData = [...table1ChartData, ...qChartData];
  }

  //sort all data by date
  table1ChartData.sort((a, b) => new Date(a.date) - new Date(b.date));

  // table 1 data
  const table1Data = [];
  let table1Dates = [];
  for (let table1ChartD of table1ChartData) {
    if (!table1Dates.includes(table1ChartD.date)) {
      table1Dates.push(table1ChartD.date);
    }
  }

  for (let table1Date of table1Dates) {
    let table1RowData = {
      date: table1Date,
      // foodInflation: ,
      // nonFoodInflation: ,
      // generalInflation: ,
    };
    for (let table1ChartD of table1ChartData) {
      if (table1ChartD.date === table1Date) {
        table1RowData[`${table1ChartD.name.replace(" ", "_")}`] =
          table1ChartD.value;
      }
    }

    table1Data.push(table1RowData);
  }

  // console.log(table1Data);
  return {
    table1ChartData,
    table1Data,
  };
};

export default table1Parser;
