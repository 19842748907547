import React, { useEffect, useState } from "react";
import "./login_page.css";
import LangBtn from "components/lang_btn";
import EmisLogo from "components/emis_logo";
import { Button, Checkbox, Form, Input, Dropdown, Menu, Modal } from "antd";
import { UserOutlined } from "@ant-design/icons";
import bgLogin from "../../assets/bg-login.svg";
import { getWindowSize } from "helpers/window-size";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { urls } from "constants/strings";

const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const USERNAME_REGEX = /^[A-z][A-z0-9-_]{5,50}$/;

const menu = (
  <Menu
    items={[
      {
        key: "1",
        label: (
          <a rel="noopener noreferrer" href="#">
            En
          </a>
        ),
      },
      {
        key: "2",
        label: (
          <a rel="noopener noreferrer" href="#">
            Am
          </a>
        ),
      },
    ]}
  />
);

function ForgotPass() {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const success = () => {
    Modal.success({
      content: "An email has been sent to you",
      onOk: () => {
        navigate(-1);
      },
    });
  };

  const error = (title, content) => {
    Modal.error({
      title,
      content,
    });
  };

  const onChange = (e) => { };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${urls.baseAuthUrl}/user/account/forgetPassword/${values.username}`
      );

      if (response.status === 200) {
        success();
      }
    } catch (err) {
      error("Error", err?.response.data.msg);
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100vh",
          backgroundImage: `url(${bgLogin})`,
          display: "flex",
          padding: "10px 50px",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <div style={{ alignSelf: "end" }}>
          <Dropdown overlay={menu} placement="bottom" arrow>
            <Button type="link">
              <LangBtn />
            </Button>
          </Dropdown>
        </div> */}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100vh",
            width: "500px",
            backgroundColor: "#fff",
            boxShadow: "2px 2px 10px #e2e2e277",
            borderRadius: "5px",
            padding: "0px 30px",
          }}
        >
          <div style={{ alignSelf: "center" }}>
            <EmisLogo />
          </div>

          <div>
            <div
              style={{
                borderBottom: "2px solid #0a7acc",
                width: "125px",
                color: "#0a7acc",
                fontSize: "1rem",
                marginBottom: "2em",
              }}
            >
              Forgot Password
            </div>

            <div style={{ width: "450px" }}>
              <Form
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      pattern: USERNAME_REGEX,
                      message: "Please enter a valid username!",
                    },
                  ]}
                >
                  <Input placeholder="johnny" />
                </Form.Item>

                <Form.Item>
                  <Button
                    loading={loading}
                    block="true"
                    type="primary"
                    htmlType="submit"
                  >
                    Send
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "400px",
            }}
          >
            <img
              style={{ marginBottom: "10px", marginTop: "10px" }}
              src={require("../../assets/logo.png")}
              height="65px"
              width="101.1px"
              alt="ess logo"
            />
            {/* <img style={{ marginBottom: '10px', marginTop: '10px' }} src={require('../../assets/ess-logo.png')} height='45.66px' width='101.1px' alt='ess logo' /> */}
            <span style={{ marginBottom: "10px", color: "#a5a0a0" }}>
              Copyright ©2022 Ethiopian Statistics Service
            </span>
            <span style={{ marginBottom: "10px", color: "#a5a0a0" }}>
              Powered by: 360Ground
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPass;
