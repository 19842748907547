import {format} from 'date-fns';
import { dateFormats } from '../constants/date_formats';

export const getFormattedDateFromDateObject = (date) => {
    return format(date, dateFormats.monthAndYear);
}

export const getFormattedDate = (date) => {
    return format(Date.parse(date), dateFormats.monthAndYear);
}

export const getFormattedDateByFormat = (date, dateFormat) => {
    return format(Date.parse(date), dateFormat);
}