import divCodes from "../../../constants/codes";
import { names } from "../../../constants/names";

const table3Parser = (responseDataFromApi) => {
  let table3ChartData = [];

  for (let qData of responseDataFromApi) {
    let qChartData = [];
    let nonFoodInflation = [];

    //* Food inflation
    for (let natDivInflation of qData.nationalDivisionInflations) {
      let divCode = natDivInflation.division.code;
      if (divCode === divCodes.foodDivCode) {
        let chartData = {};

        chartData["date"] = qData.startDate;
        chartData["name"] = "Food Inflation";
        chartData["value"] = natDivInflation.monthOnMonth.toFixed(2);

        qChartData.push(chartData);
      } else {
        // : 'Non-Food Inflation',
        nonFoodInflation.push(natDivInflation.monthOnMonth);
      }
    }

    //* non-food inflation
    let nonFoodInfSum = 0;
    for (let nonFData of nonFoodInflation) {
      nonFoodInfSum = nonFoodInfSum + nonFData;
    }

    let nonFoodInflationData = {
      date: qData.startDate,
      name: "Non-Food Inflation",
      value: Number((nonFoodInfSum / nonFoodInflation.length).toFixed(2)),
    };

    qChartData.push(nonFoodInflationData);

    //* general inflation
    let sum = 0;
    for (let chartItem of qChartData) {
      sum = sum + chartItem.value;
    }
    let generalInflation = sum / qChartData.length;

    let generalInfData = {
      date: qData.startDate,
      name: "General Inflation",
      // value: Number(generalInflation.toFixed(2)),
      value: qData.nationalGeneralInflation.monthOnMonth.toFixed(2),
    };

    qChartData.push(generalInfData);
    table3ChartData = [...table3ChartData, ...qChartData];
  }

  //sort all data by date
  table3ChartData.sort((a, b) => new Date(a.date) - new Date(b.date));

  // table 3 data
  const table3Data = [];
  let table3Dates = [];
  for (let table3ChartD of table3ChartData) {
    if (!table3Dates.includes(table3ChartD.date)) {
      table3Dates.push(table3ChartD.date);
    }
  }

  for (let table3Date of table3Dates) {
    let table3RowData = {
      date: table3Date,
      // foodInflation: ,
      // nonFoodInflation: ,
      // generalInflation: ,
    };
    for (let table3ChartD of table3ChartData) {
      if (table3ChartD.date === table3Date) {
        table3RowData[`${table3ChartD.name.replace(" ", "_")}`] =
          table3ChartD.value;
      }
    }

    table3Data.push(table3RowData);
  }

  return {
    table3ChartData,
    table3Data,
  };
};

export default table3Parser;
