const userRoles = {
    "DATA_COLLECTOR": 6789,
    "SUPERVISOR": 1456,
    "BRANCH_MANAGER": 2148,
    "ADMIN": 5278,
    "STATISTICIAN": 4362,
    "BRANCH_STATISTICIAN": 8346,
    // "DATA_CLEANER" : 7812,
    "DIRECTORATE_DIRECTOR": 3420,
    "DEPUTY_DIRECTOR_GENERAL": 9987,
    "DIRECTOR_GENERAL": 7765,
    // "SUPER_ADMIN" : 9101,
};

export const codeEnums = {
    'oldCode': 'oldCode',
    'code': 'code'
}

export const userRolesArray = [
    { name: "DATA COLLECTOR", id: 6789 },
    { name: "SUPERVISOR", id: 1456 },
    { name: "BRANCH MANAGER", id: 2148 },
    { name: "ADMIN", id: 5278 },
    { name: "STATISTICIAN", id: 4362 },
    { name: "BRANCH STATISTICIAN", id: 8346 },
    // {name: "DATA CLEANER", id: 7812},
    { name: "DIRECTORATE DIRECTOR", id: 3420 },
    { name: "DEPUTY DIRECTOR GENERAL", id: 9987 },
    { name: "DIRECTOR GENERAL", id: 7765 }
    // {name: "SUPER ADMIN", id: 9101},
];

export const userRolesArrayForQuestionnairePermission = [
    { name: "DATA COLLECTOR", id: 6789 },
    { name: "BRANCH MANAGER", id: 2148 },
    { name: "SUPERVISOR", id: 1456 },
    { name: "STATISTICIAN", id: 4362 },
    { name: "BRANCH STATISTICIAN", id: 8346 },
];

export const locationTypes = {
    "Region": "Region",
    "Zone": "Zone",
    "Woreda": "Woreda",
    "Branch": "Branch",
    "City": "City",
    "Marketplace": "Marketplace"
}

export const locationTypesArray = [
    { name: "Region" },
    { name: "Zone" },
    { name: "Woreda" },
    { name: "Branch" },
    { name: "City" },
    { name: "Marketplace" },
];

export const categoryTypes = {
    "Division": "Division",
    "Group": "Group",
    "Class": "Class",
    "Subclass": "Subclass",
}

export const CategoryTypesArray = [
    { name: "Division" },
    { name: "Group" },
    { name: "Class" },
    { name: "Subclass" },
];

export const unitOfMeasurements = {
    1: 'KG',
}

//* questionnaire related
export const questionnaireStatuses = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    PENDING_APPROVAL: "PENDING_APPROVAL",
};

export const quotationStatuses = {
    PENDING: "PENDING",
    ACCEPTED: "ACCEPTED",
    REJECTED: "REJECTED",
};

//requirements
// branchId                                     supervisor || branchmanager || branch stat
//  marketplaceId || supervisorId               data collector          


export const userRolesById = {
    6789: "DATA COLLECTOR",
    1456: "SUPERVISOR",
    2148: "BRANCH MANAGER",
    5278: "ADMIN",
    4362: "STATISTICIAN",
    8346: "BRANCH STATISTICIAN",
    7812: "DATA CLEANER",
    3420: "DIRECTORATE DIRECTOR",
    9987: "DEPUTY DIRECTOR GENERAL",
    7765: "DIRECTOR GENERAL",
    9101: "SUPER ADMIN",
}

export default userRoles;