import divCodes from "../../../constants/codes";
import { names } from "../../../constants/names";

import {
  getFormattedDateFromDateObject,
  getFormattedDateByFormat,
  getFormattedDate,
} from "../../../helpers/date_format";

const table8Parser = (responseDataFromApi) => {
  let table8Data = {
    groups: [],
    regions: [],
    data: {},
  };

  let table8ChartData = {};

  let startDates = [];
  //groupCPIs

  for (let qData of responseDataFromApi) {
    startDates.push(qData.startDate);
    for (let groupCpi of qData.groupCPIs) {
      // list regions
      if (
        table8Data.regions.find(
          (region) => region.code === groupCpi.region.code
        ) === undefined
      ) {
        let newRegion = {
          name: groupCpi.region.translation[0].name,
          code: groupCpi.region.code,
        };
        table8Data.regions.push(newRegion);
      }
    }
  }

  let sortedDataByRegion = {};

  for (let region of table8Data.regions) {
    if (sortedDataByRegion[region] === undefined) {
      sortedDataByRegion[region.code] = {};
      table8Data.data[region.code] = {
        groups: [],
        data: [],
      };
      table8ChartData[region.code] = [];
    }
  }

  for (let startDate of startDates) {
    for (let region of table8Data.regions) {
      sortedDataByRegion[region.code][startDate] = [];
    }
  }

  for (let qData of responseDataFromApi) {
    for (let groupCpi of qData.groupCPIs) {
      let dData = { ...groupCpi, startDate: qData.startDate };
      let natGenCpi = qData.generalCPIs.find(
        (gCpi) => gCpi.region.code === groupCpi.regionCode
      );

      dData.nationalGeneralCpi = natGenCpi.cpi;

      sortedDataByRegion[groupCpi.regionCode][dData.startDate].push(dData);
    }
  }

  // {
  //     cpi: 79,
  //     groupCode: 101,
  //     questionnaireId: 12,
  //     regionCode: 1,
  //     nationalCPIBasketId: 3,
  //     updatedAt: null,
  //     createdAt: '2022-12-26T07:28:04.415Z',
  //     group: [Object],
  //     region: [Object],
  //     startDate: '2022-04-01T11:41:33.000Z',
  //     nationalGeneralCpi: 93,
  //     foodAndNonAlcoholic: 294
  // },

  Object.keys(sortedDataByRegion).forEach((regionCode) => {
    Object.keys(sortedDataByRegion[regionCode]).forEach((startDate) => {
      let newRow = {
        //
      };

      let qDataFound = responseDataFromApi.find(
        (qD) => qD.startDate === startDate
      );

      for (let divCpi of qDataFound.divisionCPIs) {
        if (
          divCpi.region.code === Number(regionCode) &&
          divCpi.division.code === divCodes.foodDivCode
        ) {
          if (newRow.foodAndNonAlcoholic === undefined) {
            newRow.foodAndNonAlcoholic = divCpi.cpi;
          }
        }
      }

      for (let groupCpiData of sortedDataByRegion[regionCode][startDate]) {
        if (newRow.date === undefined) {
          newRow.date = getFormattedDate(groupCpiData.startDate);
        }

        //! food and non alcoholic groups
        const groupName = groupCpiData.group.translation[0].name;
        if (!table8Data.groups.includes(groupName)) {
          table8Data.groups.push(groupName);
        }
        newRow[groupName.replace(/ /g, "_")] = groupCpiData.cpi;
      }

      table8Data.data[regionCode].data.push(newRow);
      table8Data.data[regionCode].groups = [...table8Data.groups];
      if (table8Data.firstRegionCode === undefined) {
        table8Data.firstRegionCode = regionCode;
      }

      // console.log(newRow);
    });
  });

  //! chart data
  Object.keys(table8Data.data).forEach((regionCode) => {
    for (let tableRow of table8Data.data[regionCode].data) {
      //foodCpi
      table8ChartData[regionCode].push({
        date: tableRow.date,
        name: names.foodCategoryName,
        value: tableRow.foodAndNonAlcoholic,
      });

      for (let foodGroup of table8Data.groups) {
        table8ChartData[regionCode].push({
          date: tableRow.date,
          name: foodGroup,
          value: tableRow[foodGroup.replace(/ /g, "_")],
        });
      }
    }
  });

  // console.log(sortedDataByRegion['1']);
  // console.log({table8Data, table8ChartData});
  // console.log(table8Data.data);

  return {
    table8ChartData,
    table8Data,
  };
};

export default table8Parser;
