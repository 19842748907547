import React from "react";
import { EmisLogoWhite } from "./emis_logo";
import { SearchIcon, FAQIcon, NotificationIcon } from "./custom_icons";
import { LangBtnWhite } from "./lang_btn";
import { Button, Dropdown, Menu } from "antd";
import useAuth from "hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { apiSlice } from "features/api/apiSlice";

const TopNavbar = () => {
  const { setAuth, auth } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOut = () => {
    setAuth({});
    localStorage.removeItem("user");
    localStorage.removeItem("isLoggedIn");
    // localStorage.setItem('isLoggedIn', false);
    dispatch(apiSlice.util.resetApiState());
    navigate("/login", { replace: true });
  };

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: <span onClick={logOut}>Log out</span>,
        },
      ]}
    />
  );

  const langMenu = (
    <Menu
      items={[
        {
          key: "1",
          label: "label",
        },
      ]}
    />
  );

  return (
    <div
      style={{
        height: "60px",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        width: "100%",
        backgroundColor: "#0a7acc",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: "20px",
        }}
      >
        <img
          style={{
            borderRadius: "8px",
            transform: "scale(1.2)"
            // filter: "brightness(0.8) saturate(100%)",
          }}
          src={require("../assets/logo.png")}
          alt="Emis Logo"
          height={"auto"}
          width={"50px"}
        />
      </div>
      {/* <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '10px'}}>
            <EmisLogoWhite style={{marginLeft: '10px'}}/>
        </div> */}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginRight: "3em",
        }}
      >
        {/* <SearchIcon style={{ marginLeft: "2em" }} />
        <FAQIcon style={{ marginLeft: "2em" }} />
        <NotificationIcon style={{ marginLeft: "2em" }} /> */}
        {/* <Dropdown overlay={langMenu} placement="bottom">
                <LangBtnWhite style={{marginLeft: '1.5em'}}/>
            </Dropdown> */}
        {/* <Dropdown.Button
              style={{marginLeft: '1.5em'}}
              className="dropdown-btn"
              overlay={langMenu}
              icon={
                <LangBtnWhite />
              }
            ></Dropdown.Button> */}

        {/* <LangBtnWhite style={{ marginLeft: "1.5em" }} /> */}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: "2em",
          }}
        >
          <img
            style={{ marginBottom: "10px", marginTop: "10px" }}
            src={require("../assets/user-pro-pic.png")}
            height="24px"
            width="24px"
            alt="profile"
          />
          <Dropdown overlay={menu} placement="bottom" arrow>
            <Button type="link">
              <span style={{ color: "#fff" }}>{auth.username}</span>
              {/* <span style={{color: '#fff'}}>{users.length}</span> */}
            </Button>
          </Dropdown>
        </div>
        {/* <Button style={{marginLeft: '20px'}} onClick={logOut}>Log out</Button> */}
      </div>
    </div>
  );
};

export default TopNavbar;
