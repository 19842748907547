import { forEach } from "lodash";
import divCodes from "../../../constants/codes";
import { names } from "../../../constants/names";

const table5Parser = (responseDataFromApi) => {
  let table5Data = {
    divisions: [],
    data: [],
  };

  let table5ChartData = [];

  //! table data
  for (let questData of responseDataFromApi) {
    let newRow = {};
    newRow.date = questData.startDate;
    newRow.nationalGeneralCPI = questData.nationalGeneralCPI.cpi.toFixed(2);
    newRow.nonFoodDivisions = [];

    //! food and non alcoholic
    for (let nationalDivisionCpi of questData.nationalDivisionCPIs) {
      let divisionName = nationalDivisionCpi.division.translation[0].name;
      let divisionCode = nationalDivisionCpi.division.code;
      if (divisionCode === divCodes.foodDivCode) {
        //food division
        newRow.foodAndNonAlcoholic = nationalDivisionCpi.cpi.toFixed(2);
      } else {
        //non-food division
        let nonFoodRow = {};
        nonFoodRow.divName = divisionName;
        nonFoodRow[divisionName.replace(/ /g, "_")] =
          nationalDivisionCpi.cpi.toFixed(2);
        newRow[divisionName.replace(/ /g, "_")] =
          nationalDivisionCpi.cpi.toFixed(2);
        newRow.nonFoodDivisions.push(nonFoodRow);
      }
    }

    //! non-food index
    let nonFoodIndexSum = 0;
    for (let nonFoodDivision of newRow.nonFoodDivisions) {
      let nonFoodDivisionNumber = Number(
        nonFoodDivision[nonFoodDivision.divName.replace(/ /g, "_")]
      );
      nonFoodIndexSum = nonFoodIndexSum + nonFoodDivisionNumber;
      //   nonFoodIndexSum =nonFoodIndexSum +nonFoodDivision[nonFoodDivision.divName.replace(/ /g, "_")];
      //! for division names
      if (!table5Data.divisions.includes(nonFoodDivision.divName)) {
        table5Data.divisions.push(nonFoodDivision.divName);
      }
    }

    // console.log('nonFoodDivisions: ', newRow.nonFoodDivisions);
    // newRow.nonFoodIndex = nonFoodIndexSum / newRow.nonFoodDivisions.length;
    newRow.nonFoodIndex = (
      nonFoodIndexSum / newRow.nonFoodDivisions.length
    ).toFixed(2);

    table5Data.data.push(newRow);
  }

  table5Data.data.sort((a, b) => new Date(a.date) - new Date(b.date));

  console.log("table5Data: ", table5Data);

  //! chart data
  for (let tableRow of table5Data.data) {
    //general cpi
    table5ChartData.push({
      date: tableRow.date,
      name: "General Index",
      value: tableRow.nationalGeneralCPI,
    });

    //food cpi
    table5ChartData.push({
      date: tableRow.date,
      name: names.foodCategoryName,
      value: tableRow.foodAndNonAlcoholic,
    });

    //non-food cpi
    table5ChartData.push({
      date: tableRow.date,
      name: "Non-Food Index",
      value: tableRow.nonFoodIndex,
    });

    for (let nonFoodDiv of table5Data.divisions) {
      table5ChartData.push({
        date: tableRow.date,
        name: nonFoodDiv,
        value: tableRow[nonFoodDiv.replace(/ /g, "_")],
      });
    }
  }

  // console.log({table5ChartData, table5Data});

  /* 
    {
        date: ,
        name: ,
        value: ,
    }

    ||

    name: generalIndex, Food and non-alcoholic beverages, non-food index, nonFoodDiv1, nonFoodDiv2, nonFoodDiv3, ...
    */

  return {
    table5ChartData,
    table5Data,
  };
};

export default table5Parser;
