const LangBtn = ({style}) => {
    return (
      <div style={{display:'flex', flexDirection: 'row', alignItems: 'center', ...style, cursor: 'pointer'}}>
          <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.2298 9.69385L5.53418 11.8503H6.92542L6.2298 9.69385Z" fill="#0D99FF"/>
              <path d="M6.2298 9.69385L5.53418 11.8503H6.92542L6.2298 9.69385Z" fill="black" fillOpacity="0.2"/>
              <path d="M19.6551 0.302979H8.52519V3.78108H11.3077V5.17233H2.2646C1.08204 5.17233 0.177734 6.07663 0.177734 7.25919V14.911C0.177734 16.0936 1.08204 16.9979 2.2646 16.9979H2.96022V20.5456L7.34263 16.9979H12.0033V12.1285H19.6551C20.8377 12.1285 21.742 11.2242 21.742 10.0417V2.38984C21.742 1.20729 20.8377 0.302979 19.6551 0.302979ZM7.69044 14.1458L7.34263 13.0328H5.18621L4.76883 14.1458H3.09934L5.39489 7.95481H7.06438L9.35993 14.1458H7.69044ZM18.2639 8.65043V10.0417C17.3596 10.0417 16.3857 9.76343 15.551 9.34606C14.7162 9.76343 13.7423 9.97211 12.7685 10.0417L12.6989 8.65043C13.1859 8.65043 13.6728 8.58087 14.1597 8.44175C13.5337 7.81569 13.1163 7.0505 12.9076 6.21576H14.3684C14.5771 6.84182 14.9945 7.32875 15.4814 7.74613C16.2466 7.12007 16.7335 6.21576 16.8031 5.17233H12.6294V3.78108H14.7162V2.38984H16.1075V3.78108H18.403L18.4726 4.47671C18.5421 5.93751 17.9856 7.39831 16.9422 8.44175C17.4291 8.58087 17.8465 8.65043 18.2639 8.65043Z" fill="#0D99FF"/>
              <path d="M19.6551 0.302979H8.52519V3.78108H11.3077V5.17233H2.2646C1.08204 5.17233 0.177734 6.07663 0.177734 7.25919V14.911C0.177734 16.0936 1.08204 16.9979 2.2646 16.9979H2.96022V20.5456L7.34263 16.9979H12.0033V12.1285H19.6551C20.8377 12.1285 21.742 11.2242 21.742 10.0417V2.38984C21.742 1.20729 20.8377 0.302979 19.6551 0.302979ZM7.69044 14.1458L7.34263 13.0328H5.18621L4.76883 14.1458H3.09934L5.39489 7.95481H7.06438L9.35993 14.1458H7.69044ZM18.2639 8.65043V10.0417C17.3596 10.0417 16.3857 9.76343 15.551 9.34606C14.7162 9.76343 13.7423 9.97211 12.7685 10.0417L12.6989 8.65043C13.1859 8.65043 13.6728 8.58087 14.1597 8.44175C13.5337 7.81569 13.1163 7.0505 12.9076 6.21576H14.3684C14.5771 6.84182 14.9945 7.32875 15.4814 7.74613C16.2466 7.12007 16.7335 6.21576 16.8031 5.17233H12.6294V3.78108H14.7162V2.38984H16.1075V3.78108H18.403L18.4726 4.47671C18.5421 5.93751 17.9856 7.39831 16.9422 8.44175C17.4291 8.58087 17.8465 8.65043 18.2639 8.65043Z" fill="black" fillOpacity="0.2"/>
          </svg>
          <span style={{marginLeft: '5px'}}>{'English'}</span>
      </div>
    )
}

export const LangBtnWhite = ({style}) => {
    return (
      <div style={{display:'flex', flexDirection: 'row', alignItems: 'center', ...style, cursor: 'pointer'}}>
          <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.9625 0.285645H6.87231V3.12582H8.00858V1.42172H15.9625C16.3034 1.42172 16.5306 1.64893 16.5306 1.98975V8.23814C16.5306 8.57896 16.3034 8.80618 15.9625 8.80618H8.57672V12.7824H5.5656L3.4635 14.4297V12.7824H1.75909C1.41821 12.7824 1.19096 12.5552 1.19096 12.2144V5.966C1.19096 5.62518 1.41821 5.39796 1.75909 5.39796H9.14485V4.26189H1.75909C0.793263 4.26189 0.0546875 5.00034 0.0546875 5.966V12.2144C0.0546875 13.1801 0.793263 13.9185 1.75909 13.9185H2.32723V16.8155L5.90648 13.9185H9.71299V9.94225H15.9625C16.9283 9.94225 17.6669 9.2038 17.6669 8.23814V1.98975C17.6669 1.02409 16.9283 0.285645 15.9625 0.285645Z" fill="white"/>
            <path d="M2.44128 11.5897H3.80481L4.14569 10.6808H5.90691L6.24779 11.5897H7.61131L5.67965 6.53418H4.31613L2.44128 11.5897ZM4.99789 7.95427L5.56603 9.71518H4.42976L4.99789 7.95427Z" fill="white"/>
            <path d="M10.2806 8.23814C10.9056 8.23814 11.7578 8.06773 12.5532 7.6701C13.3486 8.06773 14.2576 8.23814 14.8257 8.23814V7.10207C14.8257 7.10207 14.2576 7.10207 13.6326 6.87485C14.3144 6.19321 14.8257 5.17074 14.8257 3.69385V3.12582H13.1213V1.98975H11.985V3.12582H10.2806V4.26189H13.6326C13.519 5.28435 13.0645 5.90919 12.5532 6.30682C12.2123 6.0228 11.8714 5.62517 11.6442 5.11394H10.4511C10.6783 5.85239 11.0192 6.42042 11.4737 6.87485C10.9056 7.10207 10.3943 7.10207 10.2806 7.10207V8.23814V8.23814Z" fill="white"/>
          </svg>

          <span style={{marginLeft: '5px', color: '#fff'}}>{'English'}</span>
      </div>
    )
}

export default LangBtn;