import axios from "axios";
import { urls } from "constants/strings";
import useAuth from "hooks/useAuth";

export default axios.create({
  baseURL: urls.baseAuthUrl,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const emisAxiosInstance = axios.create({
  baseURL: urls.baseEssUrl,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
