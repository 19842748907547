// export const urls = {
//   baseAuthUrl: "https://ess.360magic.link/auth",
//   // baseAuthUrl: "http://3.75.40.33:4567",
//   // baseAuthUrl: "http://localhost:4567",
//   baseEssUrl: "https://ess.360magic.link/backend/api",
//   // baseEssUrl: "http://3.75.40.33:4000/api",
//   // baseEssUrl: "http://localhost:4000/api",
// };

const useIp = process.env.REACT_APP_USE_IP === 'true';
console.log("uSeIp: ", useIp)

export const urls = {
  baseAuthUrl: useIp ? process.env.REACT_APP_BASE_IP_AUTH_URL : process.env.REACT_APP_BASE_DOMAIN_AUTH_URL,
  baseEssUrl: useIp ? process.env.REACT_APP_BASE_IP_ESS_URL : process.env.REACT_APP_BASE_DOMAIN_ESS_URL,
};
